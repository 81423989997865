export const convertBlobToBase64 = (blob) => {
    //console.log("blob", blob);
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};


export const handleBrokenImage = (imgLink) => {
    const imgLinkSplit = imgLink.split("https://");
    if (imgLinkSplit.length > 1) {
        const updatedLink = "https://" + imgLinkSplit[imgLinkSplit.length - 1];
        return updatedLink;
    } else {
        return "https://images.unitedwecare.com/odoo%2Fopen%2Fprofile-vector.webp"
    }
}


export const isAusDomain = () => {
    const domains = process.env.REACT_APP_LIMIT_DOMAIN.split(','); // Split domains into an array
    const isMatchingDomain = domains.some(domain => window.location.host.includes(domain));
    return isMatchingDomain;
}


export const getSubDomain = () => {
    const host = window.location.host;
    const domainPattern = /([a-zA-Z0-9-]+)\.(com|ai|com.au)$/; // Match domain like "unitedwecare.com" or "ask-sam.ai"

    const match = host.match(domainPattern);

    if (match) {
        return `.${match[1]}.${match[2]}`; // Returns "unitedwecare.com" or "ask-sam.ai"
    }
    return `.${host}`;
};

export function removeHtmlTags(text) {
    return text
        ?.replace(/<[^>]*>/g, "")  // Remove HTML tags
        ?.replace(/&nbsp;/g, "")   // Remove non-breaking spaces
        // ?.replace(/[^\w\s]/g, "")  // Remove special symbols
        ?.replace(/\s+/g, "") || ""; // Remove all spaces
}



export const hasTextChanged = (savedText, currentText) => {
    console.log(removeHtmlTags(savedText), " :old:new :",removeHtmlTags(currentText));

    return removeHtmlTags(savedText)?.trim() !== removeHtmlTags(currentText)?.trim();
};

export const isDeepEqual = (obj1, obj2) => {
    console.log("obj1: ",obj1,"obj2: ", obj2);
    
    if (obj1 === obj2) return true; // Same reference

    if (
        typeof obj1 !== "object" ||
        typeof obj2 !== "object" ||
        obj1 === null ||
        obj2 === null
    ) {
        return false; // One is not an object
    }

    // Handle arrays
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) return false; // Different lengths
        for (let i = 0; i < obj1.length; i++) {
            if (!isDeepEqual(obj1[i], obj2[i])) return false; // Recursive check for each item
        }
        return true;
    }

    // One is an array and the other is not
    if (Array.isArray(obj1) || Array.isArray(obj2)) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false; // Different number of keys

    for (const key of keys1) {
        if (!keys2.includes(key)) return false; // Key missing
        if (!isDeepEqual(obj1[key], obj2[key])) return false; // Recursive check
    }

    return true;
};