import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebaseConfig";
import { useSnackbar } from "notistack";
import { AppContext } from "../../../context";

// Components
import TextEditor from "./Components/TextEditor";
import CNReviewBar from "./CNReviewBar";
import RecommendedLabTestDialog from "./Dialogs/RecommendedLabTestDialog";
import TextEditorCommonSkeleton from "./Skeletons/TextEditorCommonSkeleton";
import LabtestsSkeleton from "./Skeletons/LabtestsSkeleton";
import FollowUpdateSkeleton from "./Skeletons/FollowUpdateSkeleton";
import FollowUpDateCaclulator from "./Components/FollowUpDateCaclulator";
import BrowseFileSkeleton from "./Skeletons/BrowseFileSkeleton";
import PrescriptionMedicineUpdate from "./Components/PrescriptionMedicineUpdate";
import HistoryPrescription from "./Dialogs/HistoryPrescription";
import NotesSkeltonLoader from "./Skeletons/NotesSkeltonLoader";

// APIs
import {
  autocompleteLabTest,
  LAMA_OCR_Document,
  InsertRagContext,
  AppendToContext,
  HandleBlurForAssistant,
} from "./ClinicalNotesAPI/ClinicalNoteAPIManager";
import { generateRandomId } from "../../core/commonFun";

// MUI
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AddCircle, Cancel, Delete, FileDownload } from "@mui/icons-material";
import HideUnhideHandler from "./Components/HideUnhideHandler";
import DownloadPrescription from "./Dialogs/DownloadPrescription";
import { hasTextChanged } from "./lib/utils";

function CNPrescription() {
  const {
    expertClinicalNotesData,
    setExpertClinicalNotesData,
    savedCopy,
    setSavedCopy,
  } = useContext(AppContext);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [firebaseUrl, setFirebaseUrl] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (
      Object.keys(expertClinicalNotesData?.data?.prescription || {}).length > 0
    ) {
      setIsLoading(false);
    }
  }, [expertClinicalNotesData?.data?.prescription]);

  const handleButtonClick = () => {
    inputFileRef.current.click();
  };

  const handleUploadOCRDocument = async () => {
    if (firebaseUrl?.length > 0) {
      let transcript = "";
      if (expertClinicalNotesData.socketRef.current) {
        expertClinicalNotesData.socketRef.current.send(
          JSON.stringify({
            action_type: "uploaded",
            details: {
              name: selectedFiles[0]?.name,
              link: firebaseUrl,
            },
            user_id: expertClinicalNotesData?.headerData?.user_id,
            expert_id: expertClinicalNotesData?.headerData?.expert_id,
          })
        );
      }
      setSelectedFiles([]);
      LAMA_OCR_Document(firebaseUrl)
        .then((res) => {
          if (res?.error) {
            enqueueSnackbar(res?.message, { variant: "info" });
            return;
          }
          transcript = res.response;
          expertClinicalNotesData.socketRef.current.send(
            JSON.stringify({
              _id: expertClinicalNotesData?.headerData?.session_id,
              raw_data: res.response,
              user_id: expertClinicalNotesData?.headerData?.user_id,
              expert_id: expertClinicalNotesData?.headerData?.expert_id,
              state: "In Progress",
            })
          );
          InsertRagContext({
            ...expertClinicalNotesData?.headerData,
            transcript: transcript,
          })
            .then(() => {
              if (res?.error) {
                enqueueSnackbar(res?.message, { variant: "info" });
                return;
              }
              setTimeout(() => {
                AppendToContext({
                  ...expertClinicalNotesData?.headerData,
                  transcript: transcript,
                }).then((res) => {
                  if (res?.error) {
                    enqueueSnackbar(res?.message, { variant: "info" });
                    return;
                  }
                });
              }, 10000);

              enqueueSnackbar(
                "Your prescription has been uploaded successfully",
                {
                  variant: "success",
                }
              );
            })
            .catch((err) => {
              console.error(err); // Optionally log the error internally here as well
            });
        })
        .catch((err) => {
          console.error(err); // Optionally log the error internally here as well
        });
    }
  };
  useEffect(() => {
    if (!expertClinicalNotesData?.headerData?.session_id) {
      return;
    }
    handleUploadOCRDocument();
  }, [firebaseUrl]);
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...files]);
    event.target.value = null;
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles([]);
  };

  const uploadFiles = async () => {
    for (const file of selectedFiles) {
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
      ];
      if (file.size > 20 * 1024 * 1024) {
        // Convert 20 MB to bytes
        enqueueSnackbar("File size should be less than 20 MB", {
          variant: "error",
        });
        return;
      }

      if (allowedTypes.includes(file.type)) {
        const dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        enqueueSnackbar("Your prescription is being uploaded. Please wait...", {
          variant: "info",
        });
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (err) => {
            console.error("Error during upload:", err);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setFirebaseUrl(url);
            });
          }
        );
      } else {
        enqueueSnackbar(
          "Invalid file type. Please select an image or PDF file.",
          {
            variant: "error",
          }
        );
        console.error("Invalid file type. Please select an image or PDF file.");
      }
    }
  };

  // console.log("ExpertClinicalNotesData", expertClinicalNotesData);
  const renderTextEditor = (key, labelText, defaultValue) => {
    return (
      <div
        className={`w-100 ${
          !expertClinicalNotesData?.data?.template?.some(
            (item) => item.name === key
          ) &&
          !expertClinicalNotesData?.editTemplate &&
          "d-none"
        }`}
      >
        <h6 className="d-flex gap-2 align-items-center">
          {labelText}
          {/* {" Completed:" +
            expertClinicalNotesData?.data?.prescription?.[key]?.completed +
            " total:" +
            expertClinicalNotesData?.data?.prescription?.[key]?.total +
            " current:" +
            expertClinicalNotesData?.data?.prescription?.[key]?.current} */}

          <HideUnhideHandler
            status={expertClinicalNotesData?.data?.template?.some(
              (item) => item.name === key
            )}
            field={key}
          />
        </h6>

        <div
          className={`${
            !expertClinicalNotesData?.data?.template?.some(
              (item) => item.name === key
            ) && "d-none"
          } w-100`}
        >
          {expertClinicalNotesData?.data?.prescription?.[key]?.display ===
          "false" ? (
            <NotesSkeltonLoader />
          ) : (
            <>
              {!expertClinicalNotesData?.data?.prescription?.[key] ? (
                <TextEditorCommonSkeleton />
              ) : (
                <>
                  <TextEditor
                    initialText={
                      expertClinicalNotesData?.data?.prescription?.[key]
                        ?.text || defaultValue
                    }
                    disabled={
                      expertClinicalNotesData?.data?.submitted ||
                      expertClinicalNotesData?.editTemplate
                    }
                    htmlText={(text) => {
                      setExpertClinicalNotesData((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState?.data,
                          prescription: {
                            ...prevState?.data?.prescription,
                            [key]: {
                              ...prevState?.data?.prescription[key],
                              text: text,
                              is_edited:
                                text != null &&
                                text?.trim() !== "" &&
                                text?.replace(/&nbsp;/g, "") !== "<p></p>\n"
                                  ? "true"
                                  : null,
                            },
                          },
                        },
                      }));
                      const list = new Set(savedCopy?.list);
                      if (
                        hasTextChanged(
                          savedCopy["prescription"]?.[key]?.text || "",
                          text
                        )
                      ) {
                        list.add(key);
                        setSavedCopy((prevState) => ({
                          ...prevState,
                          list,
                        }));
                        console.log(list);
                      } else {
                        list.delete(key);
                        setSavedCopy((prevState) => ({
                          ...prevState,
                          list,
                        }));
                      }
                    }}
                  />
                  {expertClinicalNotesData?.data?.prescription?.[key]
                    ?.accepted !== "true" && (
                    <CNReviewBar section_={"prescription"} key_={key} />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="SessionPrescription--Table p-0 p-lg-2 overflow-hidden ">
      {isLoading ? (
        <BrowseFileSkeleton />
      ) : (
        <div className="d-flex mb-3 px-lg-2 justify-content-lg-between flex-column flex-lg-row align-items-lg-center gap-2">
          <div className=" d-flex align-items-lg-center flex-column flex-lg-row gap-3 ">
            <div
              className="d-flex align-items-center rounded-1  overflow-hidden pl-2"
              style={{
                border: "1px solid #D1D5DB",
                height: "41px",
                maxWidth: "360px",
              }}
              id="prescription_browse_btn"
              onClick={handleButtonClick}
              role="button"
              disabled={
                expertClinicalNotesData?.data?.submitted ||
                expertClinicalNotesData?.editTemplate
              }
            >
              <div
                className="p-1 d-flex align-items-center"
                style={{ color: "#807C75" }}
              >
                Choose prescription to upload
              </div>
              <span
                className="p-2 fw-bold"
                style={{
                  background: "#F9FAFB",
                  borderLeft: "1px solid #D1D5DB",
                }}
              >
                Browse File
              </span>
              <input
                ref={inputFileRef}
                type="file"
                accept="image/*,.pdf"
                style={{ display: "none" }}
                id="contained-button-file"
                onChange={handleFileChange}
                disabled={
                  expertClinicalNotesData?.data?.submitted ||
                  expertClinicalNotesData?.editTemplate
                }
              />
            </div>

            <div className="d-flex justify-content-lg-between flex-wrap align-items-center">
              <Button
                onClick={uploadFiles}
                className="PrimaryCTA"
                sx={{ height: "40px", width: "100px" }}
                disabled={
                  expertClinicalNotesData?.data?.submitted ||
                  selectedFiles.length === 0
                }
                id="prescription_upload_btn"
              >
                Upload
              </Button>{" "}
              {selectedFiles.map((file, index) => (
                <div
                  key={index}
                  className="d-flex  flex-column "
                  style={{
                    width: selectedFiles.length > 2 ? "40%" : "auto",
                  }}
                >
                  <div
                    className="rounded rounded-1 mx-2 p-1 shadow-sm d-flex justify-content-between align-items-center"
                    style={{ border: "1px solid  #D1D5DB" }}
                  >
                    <div>{file.name}</div>
                    <Cancel
                      sx={{ color: "#807C75" }}
                      onClick={() => handleRemoveFile(index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex align-items-center gap-3">
            <HistoryPrescription />
          </div>
        </div>
      )}
      <div className="p-lg-2">
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor("vitals", "Vitals", "")}
          {renderTextEditor(
            "mental_status_exam_results",
            "Mental Status Exam Results",
            ""
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor(
            "physical_exam_results",
            "Physical Exam Results",
            ""
          )}
          {renderTextEditor(
            "histopathological_pathological_diagnostics",
            "Histopathological/Pathological Diagnostics",
            ""
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor(
            "imaging_and_radiological_diagnostics",
            "Imaging and Radiological Diagnostics",
            ""
          )}
          {renderTextEditor(
            "biochemical_diagnostics",
            "Biochemical Diagnostics",
            ""
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor(
            "microbiological_diagnostics",
            "Microbiological Diagnostics",
            ""
          )}
          {renderTextEditor(
            "cardiological_diagnostics",
            "Cardiological Diagnostics",
            ""
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor(
            "pulmonary_diagnostics",
            "Pulmonary Diagnostics",
            ""
          )}
          {renderTextEditor(
            "gastro_intestinal_hepatological_diagnostics",
            "Gastro-intestinal/Hepatological Diagnostics",
            ""
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor(
            "neurological_diagnostics",
            "Neurological Diagnostics",
            ""
          )}
          {renderTextEditor(
            "nephrological_diagnostics",
            "Nephrological Diagnostics",
            ""
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor(
            "urological_diagnostics",
            "Urological Diagnostics",
            ""
          )}
          {renderTextEditor(
            "ophthalmological_diagnostics",
            "Ophthalmological Diagnostics",
            ""
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor(
            "dermatological_diagnostics",
            "Dermatological Diagnostics",
            ""
          )}
          {renderTextEditor(
            "reproductive_diagnostics",
            "Reproductive Diagnostics",
            ""
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor("ent_diagnostics", "ENT Diagnostics", "")}
          {renderTextEditor(
            "endocrinological_diagnostics",
            "Endocrinological Diagnostics",
            ""
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
          {renderTextEditor(
            "orthopedic_diagnostics",
            "Orthopedic Diagnostics",
            ""
          )}
          {renderTextEditor(
            "pediatric_diagnostics",
            "Pediatric Diagnostics",
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default CNPrescription;
