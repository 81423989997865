import { Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../../context";

const ProgressNoteKeys = [
  "chief_complaints",
  "history_of_present_illness_hpi",
  "session_summary",
  "homework_assigned",
  "future_treatment_plan",
];

const FollowupsummaryKeys = [
  "compliance",
  "treatment_plan_progress",
  "assessment_summary",
  "worksheet_journal_summary",
  "stella_sos_summary",
];

const CaseHistoryKeys = [
  "medical_history",
  "history_of_self_harm_aggression",
  "history_of_trauma",
  "history_of_contagious_medical_conditions",
  "medication_history",
  "hospitalization_history",
  "surgical_history_shx",
  "histopathological_pathological_history",
  "allergy_history",
  "nutritional_history",
  "substance_use_issues",
  "genetics",
  "family_history_of_mental_illness_or_substance_abuse_issues",
  "psychosocial_history",
  "languages_spoken",
  "lifestyle_history",
  "developmental_history",
  "marital_status",
  "employment_status",
  "marital_functioning",
  "social_functioning",
  "academic_functioning",
];

const PrescriptionKeys = [
  "vitals",
  "mental_status_exam_results",
  "physical_exam_results",
  "histopathological_pathological_diagnostics",
  "imaging_and_radiological_diagnostics",
  "biochemical_diagnostics",
  "microbiological_diagnostics",
  "cardiological_diagnostics",
  "pulmonary_diagnostics",
  "gastro_intestinal_hepatological_diagnostics",
  "neurological_diagnostics",
  "nephrological_diagnostics",
  "urological_diagnostics",
  "ophthalmological_diagnostics",
  "dermatological_diagnostics",
  "reproductive_diagnostics",
  "ent_diagnostics",
  "endocrinological_diagnostics",
  "orthopedic_diagnostics",
  "pediatric_diagnostics",
];

function SaveUnsavedChanges({ onSave }) {
  const { unsavedChanges, markUnsaved, saveChanges, savedCopy } =
    useContext(AppContext);
  useEffect(() => {
    if (savedCopy?.list?.size > 0) {
      markUnsaved();
    } else {
      saveChanges();
    }
  }, [savedCopy?.list]);
  const handleSave = () => {
    onSave();
  };

  return (
    unsavedChanges && (
      <div
        style={{
          position: "fixed",
          bottom: "10%",
          left: "50%",
          zIndex: "1000",
          transform: "translateX(-50%)",
          backgroundColor: "black",
          height: "40px",
          maxWidth: "360px",
          borderRadius: "50px",
        }}
        className="w-100 shadow  shadow-lg p-1 d-flex justify-content-between align-items-center "
      >
        <p className="m-0 p-4 text-white ">Unsaved Changes </p>
        <Button
          sx={{
            backgroundColor: "var(--primary-color)",
            color: "white",
            padding: "6px 16px",
            borderRadius: "50px",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            textTransform: "none",
            height: "100%",
            minWidth: "64px",
            // border:"1px solid white",
            ":hover": {
              backgroundColor: "var(--primary-color)",
              color: "white",
            }
          }}
          className="shadow shadow-sm"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    )
  );
}

export default SaveUnsavedChanges;
