import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import ReconnectingWebSocket from "reconnecting-websocket";

// components
import { AppContext } from "../../context";
import UserInfoPanel from "./clinicalNotesClinicalRoute/Components/UserInfoPanel";
import UserSummaryPanel from "./clinicalNotesClinicalRoute/Components/UserSummaryPanel";
import CNSessionNote from "./clinicalNotesClinicalRoute/CNSessionNote";
import CNPrescription from "./clinicalNotesClinicalRoute/CNPrescription";
import CNFollowUpSummary from "./clinicalNotesClinicalRoute/CNFollowUpSummary";
import CNCaseHistory from "./clinicalNotesClinicalRoute/CNCaseHistory";
import CNRecommendation from "./clinicalNotesClinicalRoute/CNRecommendation";
import ClientCardsSkeleton from "./clinicalNotesClinicalRoute/Skeletons/ClientCardsSkeleton";
import ClientsCard from "./clinicalNotesClinicalRoute/Components/ClientsCard";
import ClinicalNoteStatus from "./clinicalNotesClinicalRoute/Components/ClinicalNoteStatus";
import CreateClinicalNote from "./clinicalNotesClinicalRoute/Dialogs/CreateClinicalNote";
import FloatingService from "./clinicalNotesClinicalRoute/AssistantComponents/FloatingService";
import CNSessionTranscript from "./clinicalNotesClinicalRoute/CNSessionTranscript";
import ClinicalNoteSubmitDialog from "./clinicalNotesClinicalRoute/Dialogs/ClinicalNoteSubmitDialog";

// APIs
import {
  DeleteClinicalNotesClient,
  FetchArchivedClinicalNotesClients,
  FetchClinicalNotesClientDetails,
  FetchClinicalNotesClients,
  FetchFollowUpNote,
  FetchSummaryFromRAG,
  InsertRagSessionData,
  onboardingTemplate,
} from "./clinicalNotesClinicalRoute/ClinicalNotesAPI/ClinicalNoteAPIManager";

//MUI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";

// MUI ICONS
import {
  Download,
  MenuOutlined,
  SaveOutlined,
  SendOutlined,
} from "@mui/icons-material";
import { createNoteSVG } from "./clinicalNotesClinicalRoute/ClinicalNoteSVGs";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Menu,
  PaginationItem,
  Typography,
} from "@mui/material";
import { set } from "date-fns";
import PDFDialog from "./clinicalNotesClinicalRoute/Dialogs/PDFDialog";
import { useAuth } from "../../auth";
import AuditLogs from "./clinicalNotesClinicalRoute/Components/AuditLogs";
import Actions from "./clinicalNotesClinicalRoute/Components/Actions";
import jwtDecode from "jwt-decode";
import SaveUnsavedChanges from "./clinicalNotesClinicalRoute/Components/SaveUnsavedChanges";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ExpertClinicalNotesDB = () => {
  const {
    expertClinicalNotesData,
    setExpertClinicalNotesData,
    setSavedCopy,
    saveChanges,
  } = useContext(AppContext);
  const [clinicalNotesState, setClinicalNotesState] = useState({
    view: "default",
  });
  const [session, setSession] = useState("In Progress");
  const [selectedTab, setSelectedTab] = useState(0);
  const [appointmentIDfromAPI, setAppointmentIDfromAPI] = useState();
  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submission, setSubmission] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [authorized, setAuthorized] = useState({ view: true, message: "" });
  const [tempData, setTempData] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pdfView, setPdfView] = useState(false);
  const [noteType, setNoteType] = useState("");
  const socketRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const storedData = localStorage.getItem("Authorization");
  const professional_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuth();
  let FIRST_LOAD = false;
  let pingInterval;
  let pingTime = 1;
  const restricteddownload = ["US", "AU"];
  const checkAndActOnIsEdited = (obj, updatedData) => {
    // console.log("obj", obj);
    // console.log("updatedData", updatedData);
    function checkForIsEdited(obj, updatedData) {
      if (typeof obj !== "object" || obj === null) {
        return; // Not an object or is null, so stop checking this branch
      }

      for (const key in obj) {
        if (!obj.hasOwnProperty(key)) continue; // Ensure the key is directly on the object

        if (key === "is_edited" && obj[key] === "true") {
          // If is_edited is "true", update the corresponding key in updatedData
          for (const k in obj) {
            if (obj.hasOwnProperty(k)) {
              updatedData[k] = obj[k];
              // console.log("inside");
            }
          }
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          if (!updatedData[key]) {
            updatedData[key] = typeof updatedData[key] === "object" ? {} : []; // Ensure the key exists in updatedData if it is an object
          }
          checkForIsEdited(obj[key], updatedData[key]);
        } else if (!updatedData.hasOwnProperty(key)) {
          updatedData[key] = obj[key]; // Copy other properties if not already present in updatedData
        }
      }
    }

    checkForIsEdited(obj, updatedData);

    return updatedData;
  };

  useEffect(() => {
    const newData = checkAndActOnIsEdited(
      expertClinicalNotesData?.data,
      tempData
    );

    setExpertClinicalNotesData((prev) => {
      return {
        ...prev,
        data: newData,
      };
    });

    const data = JSON.stringify({
      header: expertClinicalNotesData?.headerData,
      session_data: {
        ...newData,
        recommendation: expertClinicalNotesData?.recommendation,
      },
    });

    if (socketRef.current) {
      socketRef.current.send(data);
    }
  }, [tempData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const viewParam = queryParams.get("view");
    const clinicalIdParam = queryParams.get("clinicalId");

    if (viewParam === "view" && clinicalIdParam) {
      setClinicalNotesState({ view: viewParam });
      setAppointmentIDfromAPI(clinicalIdParam);
      setNoteType(queryParams.get("noteType"));
    } else {
      setClinicalNotesState({ view: "default" });
      setAppointmentIDfromAPI(null);
      setNoteType("");
    }
  }, [location.search]);

  useEffect(() => {
    if (clinicalNotesState.view === "view") {
      const newURL = `/clinical/home?view=${clinicalNotesState.view}&clinicalId=${appointmentIDfromAPI}&noteType=${noteType}`;
      navigate(newURL);
    } else {
      setExpertClinicalNotesData(null);
      setSavedCopy({
        progress_note: {},
        prescription: {},
        follow_up_summary: {},
        case_history: {},
        summary: {},
        lab_test: {},
        recommendation: {},
        list: new Set(),
      });
      setAuthorized({ view: true, message: "" });
      socketRef?.current?.close();
    }
  }, [clinicalNotesState?.view, appointmentIDfromAPI]);

  useEffect(() => {
    if (session === "Archived") {
      fetchArchivedClinicalNotesList();
    } else {
      fetchClinicalNotesList();
    }
  }, [session, clinicalNotesState?.view, page]);

  useEffect(() => {
    if (clinicalNotesState.view === "default") {
      setExpertClinicalNotesData({});
      setSavedCopy({
        progress_note: {},
        prescription: {},
        follow_up_summary: {},
        case_history: {},
        summary: {},
        lab_test: {},
        recommendation: {},
        list: new Set(),
      });
      return;
    }
    let clientInfo = {};
    let templates;
    let tabs;
    FetchClinicalNotesClientDetails(appointmentIDfromAPI).then((res) => {
      if (res?.error) {
        setAuthorized({
          view: false,
          message:
            " Sorry, you don't have permission to view these details. If you believe this is an error, please contact support. We appreciate your understanding.",
        });
        return;
      }
      const profileData = jwtDecode(res);

      onboardingTemplate(currentUser?.UserId).then((resp) => {
        if (resp.ResponseCode === 200) {
          templates = resp?.template;
          tabs = resp?.tabs;
        } else {
          enqueueSnackbar(resp?.ResponseMessage, { variant: "error" });
          return;
        }
      });

      setAuthorized({ view: true, message: "" });
      setExpertClinicalNotesData((prevData) => ({
        ...prevData,
        client: profileData,
        data: {
          ...prevData?.data,
          client: profileData,
        },
      }));
      clientInfo = profileData;
      FIRST_LOAD = true;
      socketRef.current = new ReconnectingWebSocket(
        `${process.env.REACT_APP_EXPERT_BASE_WEBSOCKET_URL}ws?authorization=${
          storedData?.split("Bearer ")[1]
        }&appointment_id=${appointmentIDfromAPI}`
      );
      socketRef.current.addEventListener("open", (event) => {
        console.log("WebSocket connection opened!");
        socketRef.current.send("ping");
        pingInterval = setInterval(() => {
          socketRef.current.send("ping");
        }, pingTime * 8000);
        socketRef.current.pingInterval = pingInterval;
      });

      socketRef.current.addEventListener("message", (event) => {
        console.log("Message received from server!");

        try {
          let resp_data = JSON.parse(event.data);

          const data = resp_data?.session_data;
          const headerData = resp_data?.header;
          const updatedData = {
            ...data,
            progress_note: {
              ...data?.progress_note,
              medicines: {
                ...data?.progress_note?.medicines,
                prescribed_medicines:
                  data?.progress_note?.medicines?.prescribed_medicines?.filter(
                    (item) =>
                      item?.drug !== "" &&
                      item?.drug != null &&
                      item?.drug !== undefined
                  ),
              },
            },
            lab_test: {
              ...data?.lab_test,
              prescribed_lab_test: data?.lab_test?.prescribed_lab_test?.filter(
                (item) =>
                  item?.test_name !== "" &&
                  item?.test_name != null &&
                  item?.test_name !== undefined
              ),
            },

            template:
              resp_data?.session_data?.template?.length > 0
                ? resp_data?.session_data?.template
                : templates,
            tab:
              resp_data?.session_data?.tab?.length > 0
                ? resp_data?.session_data?.tab
                : tabs,
            client: clientInfo,
            updated: "false",
          };
          if (FIRST_LOAD) {
            setExpertClinicalNotesData((prevData) => ({
              ...prevData,
              data: {
                ...updatedData,
              },
              headerData: headerData,
              recommendation: updatedData?.recommendation,
              socketRef: socketRef,

              template:
                resp_data?.session_data?.template?.length > 0
                  ? resp_data?.session_data?.template
                  : templates,
              tab:
                resp_data?.session_data?.tab?.length > 0
                  ? resp_data?.session_data?.tab
                  : tabs,
            }));

            socketRef.current.send(
              JSON.stringify({
                header: headerData,
                session_data: {
                  ...updatedData,
                },
              })
            );
            setSavedCopy((prev) => ({
              ...prev,
              progress_note: updatedData?.progress_note,
              prescription: updatedData?.prescription,
              follow_up_summary: updatedData?.follow_up_summary,
              case_history: updatedData?.case_history,
              summary: updatedData?.summary,
              lab_test: updatedData?.lab_test?.prescribed_lab_test,
              recommendation: updatedData?.recommendation,
            }));
            FIRST_LOAD = false;
          }
          if (data?.updated?.toLowerCase() === "true") {
            setTempData(updatedData);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      });

      socketRef.current.addEventListener("close", (event) => {
        console.log("WebSocket connection closed!");
        if (socketRef.current.pingInterval) {
          clearInterval(socketRef.current.pingInterval);
          socketRef.current.pingInterval = null;
        }
      });
    });
  }, [clinicalNotesState?.view]);

  useEffect(() => {
    if (
      clinicalNotesState.view === "default" ||
      !expertClinicalNotesData?.headerData?.user_id
    ) {
      return;
    }
    const data = JSON.stringify({
      header: expertClinicalNotesData?.headerData,
      session_data: { ...expertClinicalNotesData?.data, assistant: {} },
    });

    InsertRagSessionData(data).then((res) => {
      if (res?.error) {
        enqueueSnackbar(res?.message, { variant: "info" });
        return;
      }
      console.log("Data sent to server");
    });

    let expert_info = JSON.parse(window.localStorage.getItem("profile"));

    FetchFollowUpNote(expertClinicalNotesData?.headerData?.user_id, 10).then(
      (res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          return;
        }

        setExpertClinicalNotesData((prevData) => ({
          ...prevData,
          expert_info: expert_info,
          followUpNotes: jwtDecode(res),
        }));
      }
    );
  }, [expertClinicalNotesData?.headerData?.user_id]);

  const fetchClinicalNotesList = async () => {
    if (clinicalNotesState.view === "view") {
      return;
    }

    try {
      if (!professional_id) return;
      setLoading(true);
      const clients = await FetchClinicalNotesClients(
        professional_id,
        session,
        page
      );
      if (clients?.error) {
        enqueueSnackbar(clients?.message, { variant: "info" });
        return;
      }
      const clientData = jwtDecode(clients?.data);

      setTotalPages(clients?.total_pages);

      setClientsList(clientData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchArchivedClinicalNotesList = async () => {
    if (clinicalNotesState.view === "view") {
      return;
    }
    setLoading(true);
    try {
      const clients = await FetchArchivedClinicalNotesClients(
        professional_id,
        session,
        page
      );
      if (clients?.error) {
        enqueueSnackbar(clients?.message, { variant: "info" });
        return;
      }
      const clientData = jwtDecode(clients?.data);

      setTotalPages(clients?.total_pages);

      setClientsList(clientData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSummary = async () => {
    setSummaryLoading(true);

    const data = JSON.stringify({
      header: expertClinicalNotesData?.headerData,
      session_data: {
        ...expertClinicalNotesData?.data,
        recommendation: expertClinicalNotesData?.recommendation,
      },
    });
    const rag_data = JSON.stringify({
      header: expertClinicalNotesData?.headerData,
      session_data: {
        ...expertClinicalNotesData?.data,
        assistant: {},
        recommendation: expertClinicalNotesData?.recommendation,
      },
    });
    if (socketRef.current) {
      socketRef.current.send(data);
    }
    InsertRagSessionData(rag_data).then((res) => {
      setTimeout(() => {
        FetchSummaryFromRAG(
          expertClinicalNotesData?.headerData?.user_id,
          expertClinicalNotesData?.headerData?.session_id
        )
          .then((res) => {
            if (res?.error) {
              enqueueSnackbar(res?.message, { variant: "info" });
              setSummaryLoading(false);
              return;
            }
            setExpertClinicalNotesData((prevData) => ({
              ...prevData,
              data: {
                ...prevData.data,
                summary: res,
              },
            }));
            const data = JSON.stringify({
              header: expertClinicalNotesData?.headerData,
              session_data: {
                ...expertClinicalNotesData?.data,
                summary: res,
              },
            });
            if (socketRef.current) {
              socketRef.current.send(data);
            }
            setSummaryLoading(false);
          })
          .catch((err) => {
            console.error(err); // Optionally log the error internally here as well
          });
      }, 4000);
    });
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const recommendationHandlerOnSave = () => {
    const previouseRecommendation =
      expertClinicalNotesData?.data?.recommendation;
    const newRecommendation = expertClinicalNotesData?.recommendation;
    const existingExperts = previouseRecommendation?.recommended_expert?.map(
      (item) => item?.Name
    );
    newRecommendation?.recommended_expert?.map((item) => {
      if (!existingExperts?.includes(item?.Name)) {
        expertClinicalNotesData.socketRef.current.send(
          JSON.stringify({
            action_type: "recommended",
            details: {
              recommendation: item?.Name,
            },
            user_id: expertClinicalNotesData?.headerData?.user_id,
            expert_id: expertClinicalNotesData?.headerData?.expert_id,
          })
        );
      }
    });
    const existingPrograms = previouseRecommendation?.recommended_program?.map(
      (item) => item?.name
    );
    console.log(
      existingPrograms,
      "existingPrograms",
      newRecommendation?.recommended_program
    );

    newRecommendation?.recommended_program?.map((item) => {
      if (!existingPrograms?.includes(item?.name)) {
        expertClinicalNotesData.socketRef.current.send(
          JSON.stringify({
            action_type: "recommended",
            details: {
              recommendation: item?.name,
            },
            user_id: expertClinicalNotesData?.headerData?.user_id,
            expert_id: expertClinicalNotesData?.headerData?.expert_id,
          })
        );
      }
    });
    const existingAssesments =
      previouseRecommendation?.recommended_assessment?.map(
        (item) => item?.title
      );
    newRecommendation?.recommended_assessment?.map((item) => {
      if (!existingAssesments?.includes(item?.title)) {
        expertClinicalNotesData.socketRef.current.send(
          JSON.stringify({
            action_type: "recommended",
            details: {
              recommendation: item?.title,
            },
            user_id: expertClinicalNotesData?.headerData?.user_id,
            expert_id: expertClinicalNotesData?.headerData?.expert_id,
          })
        );
      }
    });
    const existingContents = previouseRecommendation?.recommended_content?.map(
      (item) => item?.data?.title
    );
    newRecommendation?.recommended_content?.map((item) => {
      if (!existingContents?.includes(item?.data?.title)) {
        expertClinicalNotesData.socketRef.current.send(
          JSON.stringify({
            action_type: "recommended",
            details: {
              recommendation: item?.data?.title,
            },
            user_id: expertClinicalNotesData?.headerData?.user_id,
            expert_id: expertClinicalNotesData?.headerData?.expert_id,
          })
        );
      }
    });
  };

  const saveSession = () => {
    const data = JSON.stringify({
      header: expertClinicalNotesData?.headerData,
      session_data: {
        ...expertClinicalNotesData?.data,
        recommendation: expertClinicalNotesData?.recommendation,
        progress_note: {
          ...expertClinicalNotesData?.data?.progress_note,
          medicines: {
            ...expertClinicalNotesData?.data?.progress_note?.medicines,
            prescribed_medicines:
              expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                (item) =>
                  item?.drug !== "" &&
                  item?.drug != null &&
                  item?.drug !== undefined
              ),
          },
        },
        lab_test: {
          ...expertClinicalNotesData?.data?.lab_test,
          prescribed_lab_test:
            expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
              (item) =>
                item?.test_name !== "" &&
                item?.test_name != null &&
                item?.test_name !== undefined
            ),
        },
      },
    });

    const rag_data = JSON.stringify({
      header: expertClinicalNotesData?.headerData,
      session_data: {
        ...expertClinicalNotesData?.data,
        assistant: {},
        recommendation: expertClinicalNotesData?.recommendation,
        progress_note: {
          ...expertClinicalNotesData?.data?.progress_note,
          medicines: {
            ...expertClinicalNotesData?.data?.progress_note?.medicines,
            prescribed_medicines:
              expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                (item) =>
                  item?.drug !== "" &&
                  item?.drug != null &&
                  item?.drug !== undefined
              ),
          },
        },
        lab_test: {
          ...expertClinicalNotesData?.data?.lab_test,
          prescribed_lab_test:
            expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
              (item) =>
                item?.test_name !== "" &&
                item?.test_name != null &&
                item?.test_name !== undefined
            ),
        },
      },
    });
    setSavedCopy((prev) => ({
      ...prev,
      progress_note: expertClinicalNotesData?.data?.progress_note || {},
      prescription: expertClinicalNotesData?.data?.prescription || {},
      follow_up_summary: expertClinicalNotesData?.data?.follow_up_summary || {},
      case_history: expertClinicalNotesData?.data?.case_history || {},
      summary: expertClinicalNotesData?.data?.summary || {},
      lab_test:
        expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test || {},
      recommendation: expertClinicalNotesData?.recommendation || undefined,
      list: new Set(),
    }));
    saveChanges();
    recommendationHandlerOnSave();
    if (socketRef.current) {
      socketRef.current.send(data);
      expertClinicalNotesData.socketRef.current.send(
        JSON.stringify({
          action_type: "note_saved",
          details: {
            note_type: noteType,
            patient_name: expertClinicalNotesData?.data?.client?.name,
          },
          user_id: expertClinicalNotesData?.headerData?.user_id,
          expert_id: expertClinicalNotesData?.headerData?.expert_id,
        })
      );
    }
    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      data: {
        ...expertClinicalNotesData?.data,
        recommendation: expertClinicalNotesData?.recommendation,
        progress_note: {
          ...expertClinicalNotesData?.data?.progress_note,
          medicines: {
            ...expertClinicalNotesData?.data?.progress_note?.medicines,
            prescribed_medicines:
              expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                (item) =>
                  item?.drug !== "" &&
                  item?.drug != null &&
                  item?.drug !== undefined
              ),
          },
        },
        lab_test: {
          ...expertClinicalNotesData?.data?.lab_test,
          prescribed_lab_test:
            expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
              (item) =>
                item?.test_name !== "" &&
                item?.test_name != null &&
                item?.test_name !== undefined
            ),
        },
      },
      askStellaConversation: "init",
    }));
    InsertRagSessionData(rag_data).then((res) => {
      if (res?.error) {
        enqueueSnackbar(res?.message, { variant: "info" });
        setExpertClinicalNotesData((prevData) => ({
          ...prevData,
          askStellaConversation: "mid",
        }));
        return;
      }
      console.log("Data sent to server");
      setExpertClinicalNotesData((prevData) => ({
        ...prevData,
        askStellaConversation: "end",
      }));
    });
    enqueueSnackbar("Your clinical note has been saved!", {
      variant: "success",
    });
    setTimeout(() => {
      FetchFollowUpNote(expertClinicalNotesData?.headerData?.user_id, 10)
        .then((res) => {
          if (res?.error) {
            enqueueSnackbar(res?.message, { variant: "info" });
            return;
          }
          setExpertClinicalNotesData((prevData) => ({
            ...prevData,
            followUpNotes: jwtDecode(res),
          }));
        })
        .catch((err) => {
          console.error(err); // Optionally log the error internally here as well
        });
    }, 3000);
  };

  const handleSubmit = () => {
    const data = JSON.stringify({
      header: expertClinicalNotesData?.headerData,
      session_data: {
        ...expertClinicalNotesData?.data,
        progress_note: {
          ...expertClinicalNotesData?.data?.progress_note,
          medicines: {
            ...expertClinicalNotesData?.data?.progress_note?.medicines,
            prescribed_medicines:
              expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                (item) =>
                  item?.drug !== "" &&
                  item?.drug != null &&
                  item?.drug !== undefined
              ),
          },
        },
        lab_test: {
          ...expertClinicalNotesData?.data?.lab_test,
          prescribed_lab_test:
            expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
              (item) =>
                item?.test_name !== "" &&
                item?.test_name != null &&
                item?.test_name !== undefined
            ),
        },
        submitted: true,
      },
    });
    const rag_data = JSON.stringify({
      header: expertClinicalNotesData?.headerData,
      session_data: {
        ...expertClinicalNotesData?.data,
        assistant: {},
        progress_note: {
          ...expertClinicalNotesData?.data?.progress_note,
          medicines: {
            ...expertClinicalNotesData?.data?.progress_note?.medicines,
            prescribed_medicines:
              expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                (item) =>
                  item?.drug !== "" &&
                  item?.drug != null &&
                  item?.drug !== undefined
              ),
          },
        },
        lab_test: {
          ...expertClinicalNotesData?.data?.lab_test,
          prescribed_lab_test:
            expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
              (item) =>
                item?.test_name !== "" &&
                item?.test_name != null &&
                item?.test_name !== undefined
            ),
        },
        submitted: true,
      },
    });
    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      data: {
        ...prevData?.data,
        submitted: true,
      },
    }));
    if (socketRef.current) {
      socketRef.current.send(data);
      expertClinicalNotesData.socketRef.current.send(
        JSON.stringify({
          action_type: "note_submitted",
          details: {
            note_type: noteType,
            patient_name: expertClinicalNotesData?.data?.client?.name,
          },
          user_id: expertClinicalNotesData?.headerData?.user_id,
          expert_id: expertClinicalNotesData?.headerData?.expert_id,
        })
      );
    }
    InsertRagSessionData(rag_data)
      .then((res) => {
        console.log("Data sent to server");
        enqueueSnackbar("Your note has been submitted successfully", {
          variant: "success",
        });
        setTimeout(() => {
          setClinicalNotesState((prevState) => ({
            ...prevState,
            view: "default",
          }));
        }, 2000);
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        console.error(err); // Optionally log the error internally here as well
      });
  };

  const handleDeleteClinicalNote = async (data) => {
    setLoading(true);
    DeleteClinicalNotesClient(data?._id)
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          setLoading(false);
          return;
        }
        fetchClinicalNotesList();
        setLoading(false);
      })
      .catch((err) => {
        console.error(err); // Optionally log the error internally here as well
      });
  };
  const sendPing = () => {
    if (socketRef.current) {
      socketRef.current.send("ping");
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const tabsArray = [
    "Session Note",
    "Prescription",
    "Follow-Up Summary",
    "Case History",
  ];

  const tabComponents = {
    "Session Note": {
      component: (
        <>
          <CNSessionNote />
          <CNRecommendation />
        </>
      ),
      name: "Clinical Advice",
    },
    Prescription: {
      component: <CNPrescription />,
      name: "Clinical Examination",
    },
    "Follow-Up Summary": {
      component: <CNFollowUpSummary />,
      name: "Follow-Up Note",
    },
    "Case History": {
      component: <CNCaseHistory />,
      name: "Case History",
    },
  };

  const renderedTabs = expertClinicalNotesData?.editTemplate
    ? tabsArray
    : tabsArray.filter((tab) =>
        expertClinicalNotesData?.data?.tab?.includes(tab)
      );

  return (
    <div className=" position-relative">
      <div
        style={{
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "22px",
          color: "#000000",
        }}
        className="d-flex align-items-md-center flex-column flex-md-row justify-content-md-between gap-2 "
      >
        {clinicalNotesState.view === "view" ? (
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                id="back_to_clinical_notes"
                onClick={() => navigate("/clinical/home")}
                role="button"
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                  textDecoration: "none",
                }}
                className="d-flex text-secondary align-items-center justify-content-between gap-2 "
              >
                Clinical Co-Pilot{" "}
                <span
                  style={{
                    fontSize: "14px",
                    background: "#DCFAE6",
                    color: "#079455",
                    border: "1px solid #079455",
                  }}
                  className="  px-2  rounded-pill "
                >
                  Beta
                </span>
              </Link>

              {expertClinicalNotesData?.client?.name && (
                <Typography
                  sx={{ fontWeight: "500", fontSize: "20px" }}
                  color="text.primary"
                >
                  {expertClinicalNotesData?.client?.name}
                </Typography>
              )}
            </Breadcrumbs>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-between gap-2 ">
            <Link
              id="back_to_clinical_notes"
              color="text.primary"
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                textDecoration: "none",
              }}
              role="button"
              onClick={() => navigate("/clinical/home")}
              className="d-flex my-3 align-items-center justify-content-between gap-2 "
            >
              Clinical Co-Pilot{" "}
              <span
                style={{
                  fontSize: "14px",
                  background: "#DCFAE6",
                  color: "#079455",
                  border: "1px solid #079455",
                }}
                className="  px-2  rounded-pill "
              >
                Beta
              </span>
            </Link>
          </div>
        )}
        {
          <>
            {" "}
            <div
              style={{ zIndex: "200", background: "white", width: "100vw" }}
              className="p-2 d-flex d-md-none justify-content-center position-fixed bottom-0 border-top border-2 translate-middle-x start-50"
            >
              {!expertClinicalNotesData?.data?.submitted &&
              expertClinicalNotesData?.headerData?.user_id &&
              clinicalNotesState.view === "view" ? (
                <div className=" d-flex clinicalnotes-submit-save justify-content-md-between gap-2 ">
                  <Actions downloadPDF={() => setPdfView(true)} />
                  <Button
                    className="save "
                    onClick={saveSession}
                    disabled={expertClinicalNotesData?.editTemplate}
                  >
                    <SaveOutlined sx={{ color: "var(--primary-color)" }} />
                    Save
                  </Button>
                  <Button
                    disabled={expertClinicalNotesData?.editTemplate}
                    id="submit_clinical_note"
                    className="PrimaryCTA "
                    onClick={() => setSubmission(true)}
                  >
                    Submit
                    <SendOutlined />
                  </Button>
                </div>
              ) : (
                expertClinicalNotesData?.data?.submitted &&
                expertClinicalNotesData?.headerData?.user_id && (
                  <>
                    <Actions downloadPDF={() => setPdfView(true)} />
                  </>
                )
              )}
            </div>
            <div className="d-none d-md-block">
              {!expertClinicalNotesData?.data?.submitted &&
              expertClinicalNotesData?.headerData?.user_id &&
              clinicalNotesState.view === "view" ? (
                <div className=" d-flex clinicalnotes-submit-save justify-content-md-between gap-2 ">
                  <Actions downloadPDF={() => setPdfView(true)} />
                  <Button
                    id="save_clinical_note"
                    className="save "
                    onClick={saveSession}
                    disabled={expertClinicalNotesData?.editTemplate}
                  >
                    <SaveOutlined sx={{ color: "var(--primary-color)" }} />
                    Save
                  </Button>
                  <Button
                    id="submit_clinical_note"
                    className="PrimaryCTA "
                    onClick={() => setSubmission(true)}
                    disabled={expertClinicalNotesData?.editTemplate}
                  >
                    Submit
                    <SendOutlined />
                  </Button>
                </div>
              ) : (
                expertClinicalNotesData?.data?.submitted &&
                expertClinicalNotesData?.headerData?.user_id && (
                  <>
                    <Actions downloadPDF={() => setPdfView(true)} />
                  </>
                )
              )}
            </div>
          </>
        }
      </div>
      {clinicalNotesState.view === "default" ? (
        <>
          <div className="d-flex align-items-lg-center justify-content-between flex-column flex-lg-row gap-2 ">
            <div>
              <ClinicalNoteStatus
                session={session}
                setSession={(data) => {
                  setPage(1);
                  setSession(data);
                }}
              />
            </div>

            <div
              style={{
                display: `${clientsList?.length === 0 ? "none" : "block"}`,
              }}
              className="create-note-button"
            >
              <CreateClinicalNote
                top={true}
                clientsList={clientsList?.length}
                setNoteType={(data) => setNoteType(data)}
                setAppointmentIDfromAPI={setAppointmentIDfromAPI}
                setClinicalNotesState={setClinicalNotesState}
                fetchClinicalNotesList={fetchClinicalNotesList}
              />
            </div>
          </div>

          {loading ? (
            <div
              className="row rows row-cols-1 border row-cols-md-2 row-cols-xl-3 row-cols-xxl-4  p-md-3 mx-1 mt-4 rounded-4  "
              style={{ background: "white" }}
            >
              {Array.from(new Array(8)).map((_, index) => (
                <ClientCardsSkeleton key={index} />
              ))}
            </div>
          ) : (
            <div>
              {clientsList?.length > 0 && (
                <>
                  <div
                    style={{
                      background: "#f9f9f9",
                    }}
                    className="row rows row-cols-1 border row-cols-md-2 row-cols-xl-3 row-cols-xxl-4  p-md-3 mx-1 mt-4 rounded-4 "
                  >
                    <>
                      {clientsList?.map((data, index) => (
                        <ClientsCard
                          setNoteType={(data) => setNoteType(data)}
                          key={index}
                          data={data}
                          handleView={(data) => {
                            setClinicalNotesState({ view: "view" });
                            setAppointmentIDfromAPI(data?._id);
                          }}
                          handleDelete={(data) =>
                            handleDeleteClinicalNote(data)
                          }
                        />
                      ))}
                    </>
                  </div>
                  <div className="d-flex justify-content-end mb-5 p-4">
                    <Box
                      sx={{
                        "& .MuiPaginationItem-root": {
                          "&.Mui-selected": {
                            backgroundColor: "var(--primary-color)", // Selected page background color
                            color: "#fff", // Selected page text color
                          },
                          "&:hover": {
                            backgroundColor: "var(--primary-color)", // Hover background color
                          },
                        },
                      }}
                    >
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChange}
                        renderItem={(item) => <PaginationItem {...item} />}
                      />
                    </Box>
                  </div>
                </>
              )}

              {clientsList?.length === 0 && (
                <div
                  style={{ height: "500px" }}
                  className="d-flex flex-column align-items-center justify-content-center  mx-1 mt-4 w-100 gap-4"
                >
                  {createNoteSVG}
                  <p
                    style={{
                      color: "#667085",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "28px",
                      maxWidth: "500px",
                    }}
                  >
                    {session === "In Progress"
                      ? ` Get Started with Your First Clinical Note. Just Hit the Button Below!`
                      : `No ${
                          session === "all" ? "" : session?.toLowerCase()
                        } notes found.`}
                  </p>
                  {session === "In Progress" && (
                    <CreateClinicalNote
                      clientsList={clientsList?.length}
                      setNoteType={(data) => setNoteType(data)}
                      setAppointmentIDfromAPI={setAppointmentIDfromAPI}
                      setClinicalNotesState={setClinicalNotesState}
                      fetchClinicalNotesList={fetchClinicalNotesList}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={`clinicalDashboard--scroll ${
              authorized?.view ? "" : "d-none"
            }`}
          >
            <UserInfoPanel
              sendPing={sendPing}
              loading={loading}
              downloadPDF={() => setPdfView(true)}
            />
            <UserSummaryPanel
              genSummary={fetchSummary}
              loading={loading}
              summaryLoading={summaryLoading}
            />
            {expertClinicalNotesData?.headerData?.user_id &&
              !expertClinicalNotesData?.editTemplate && (
                <div className=" sessionManagement--floatingContainer">
                  <FloatingService
                    screen="SessionNotes"
                    data={expertClinicalNotesData}
                  />
                </div>
              )}
            <PDFDialog open={pdfView} setOpen={(data) => setPdfView(data)} />
            <SaveUnsavedChanges onSave={saveSession} />
            <div
              className="mt-4 rounded rounded-4 position-relative border"
              style={{ backgroundColor: "white", overflowX: "auto" }}
            >
              <div className="SessionDashboard--TabsContainer px-md-4">
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  className="SessionDashboard--Tabs"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  {renderedTabs.map((tab, index) => (
                    <Tab
                      key={index}
                      id={tab.replace(/\s+/g, "")} // Use a sanitized version of the tab name for id
                      label={tabComponents[tab].name} // Corrected to fetch the name from the tabComponents object
                    />
                  ))}
                </Tabs>
              </div>

              {renderedTabs.map((tab, index) => (
                <CustomTabPanel key={index} value={selectedTab} index={index}>
                  <div
                    style={{ paddingBottom: "100px" }}
                    className="SessionTab--Panel p-2 p-md-4 py-4 SessionSessionNote--Panel"
                  >
                    {tabComponents[tab].component}
                  </div>
                </CustomTabPanel>
              ))}
            </div>

            <div style={{ height: "100px" }}></div>
          </div>
          <div
            style={{ height: "100vh" }}
            className={`w-100  d-flex flex-column gap-5 justify-content-center  align-items-center
           ${authorized?.view ? "d-none" : ""}`}
          >
            <img width={200} src="/assets/img/no-worksheets.png" />
            <p
              style={{
                color: "var(--Text-text-secondary, #252422)",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "28px",
                maxWidth: "800px",
              }}
            >
              {authorized?.message}
            </p>
            <div className="w-100 d-flex justify-content-center gap-2 gap-md-4   ">
              <Button
                className="PrimaryCTA"
                onClick={() => {
                  setAuthorized({ view: true, message: "" });
                  navigate("/clinical/home");
                }}
                id="GoToClinicalCoPilot"
              >
                Go to Clinical Co-Pilot
              </Button>
              <Button
                className="PrimaryCTA--Outlined"
                onClick={() => {
                  setAuthorized({ view: true, message: "" });
                  navigate("/clinical/help-center");
                }}
                id="GoToHelpCenter"
              >
                Contact Support
              </Button>
            </div>
          </div>
        </>
      )}

      <ClinicalNoteSubmitDialog
        submission={submission}
        setSubmission={() => {
          setSubmission(false);
        }}
        handleSave={() => {
          saveSession();
          setSubmission(false);
          setClinicalNotesState((prevState) => ({
            ...prevState,
            view: "default",
          }));
        }}
        handleSubmit={() => {
          setSubmission(false);
          handleSubmit();
        }}
      />
    </div>
  );
};

export default ExpertClinicalNotesDB;
