import React, { useEffect, useState, useRef, useContext } from "react";
import { useAuth } from "../../../../auth";

import moment from "moment";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebaseConfig";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

// Component
import AssistantItem from "./AssistantItem";
import GraphVisualization from "../Components/GraphVisualization";

// APIs
import {
  HandleBlurForAssistant,
  InsertRagContext,
  LAMA_OCR_Document,
} from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import { convertBlobToBase64, isAusDomain } from "../lib/utils";
import { fetchSTTWhisper } from "../../APIS/clientAPIS";

// MUI
import {
  ArrowRightAlt,
  AttachFile,
  Cached,
  Clear,
  Close,
  ContentCopyOutlined,
  Mic,
  NotificationsOffRounded,
  ReportProblemOutlined,
  Stop,

} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Tab,
  Tabs,
  TextareaAutosize,
} from "@mui/material";
import { AppContext } from "../../../../context";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px, 10px, 10px, 10px",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const messages = [
  `Welcome to ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}! I'm dedicated to supporting your clinical practice with seamless access to essential patient information, enabling you to make well-informed and compassionate decisions.`,
  `Welcome to ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}! My goal is to be your trusted copilot, providing comprehensive patient histories at your fingertips, so you can focus on delivering the best possible care.`,
  `Welcome to ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}! I'm here to assist you with quick access to detailed patient records, helping you make confident and informed clinical choices for your patients.`,
  `Welcome to ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}! I empower you with instant access to critical patient data, ensuring you have the information you need to provide excellent and empathetic care.`,
  `Welcome to ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}! With detailed patient histories readily available, I'm here to support your clinical decisions and help you deliver the highest quality of care.`,
  `Welcome to ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}! I'm committed to enhancing your clinical workflow by providing vital patient insights, allowing you to make informed decisions with confidence and care.`,
  `Welcome to ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}! My aim is to support your clinical expertise with comprehensive patient information, helping you to provide thoughtful and effective care.`
];
const InitialMessage = messages[Math.floor(Math.random() * messages.length)]
const AssistantModal = ({ show, close }) => {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const [currentTab, setCurrentTab] = useState("assistant");
  const [currentSubTab, setCurrentSubContent] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1);
  const [files, setFiles] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [transcriptValue, setTranscriptValue] = useState("");
  const chatContainerRef = useRef(null);
  const { currentUser } = useAuth();
  const [tabs, setTabs] = useState([]);
  const [showAskstellaPreviewMessage, setShowAskstellaPreviewMessage] = useState({
    show: false,
    message: "I'm back and ready to assist you!",
  });

  const { enqueueSnackbar } = useSnackbar();

  const profileImage = currentUser?.ProfileImage?.replace(
    "https://app.uwc.worldhttps://",
    "https://"
  );

  useEffect(() => {
    setTranscriptValue("");
  }, [currentTab, show]);

  useEffect(() => {
    setTabs([
      {
        icon: <ReportProblemOutlined />,
        tabName: "Alert",
        value: expertClinicalNotesData?.data?.assistant?.alerts?.alert_number,
        bgColor: "#F63D681A",
        fontColor: "#F63D68",
      },
      {
        icon:
          currentSubTab === 1 ? (
            <img width={20} src="/assets/img/elements/mediwhite.png" />
          ) : (
            <img width={20} src="/assets/img/elements/medication.png" />
          ),
        tabName: "Medications",
        value:
          expertClinicalNotesData?.data?.assistant?.medications?.alert_number,
        bgColor: "#FB65141A",
        fontColor: "#FB6514",
      },
      {
        icon:
          currentSubTab === 2 ? (
            <img width={20} src="/assets/img/elements/diagwhite.png" />
          ) : (
            <img width={20} src="/assets/img/elements/diagnostics.png" />
          ),
        tabName: "Diagnostics",
        value:
          expertClinicalNotesData?.data?.assistant?.diagnostics?.alert_number,
        bgColor: "#FDB0221A",
        fontColor: "#FDB022",
      },
      // {
      //   icon: <VerifiedUserOutlined />,
      //   tabName: "Guidelines",
      //   value: "",
      //   bgColor: "#12B76A1A",
      //   fontColor: "#12B76A",
      // },
      // {
      //   icon: <Spa />,
      //   tabName: "Admin",
      //   value: "",
      //   bgColor: "#2E90FA1A",
      //   fontColor: "#2E90FA",
      // },
    ]);
  }, [
    expertClinicalNotesData?.data?.assistant?.alerts?.alert_number,
    expertClinicalNotesData?.data?.assistant?.medications?.alert_number,
    expertClinicalNotesData?.data?.assistant?.diagnostics?.alert_number,
    currentSubTab,
  ]);

  useEffect(() => {
    if (expertClinicalNotesData?.data?.submitted) return;
    if (expertClinicalNotesData?.askStellaConversation === "init") {
      setShowAskstellaPreviewMessage({
        show: true,
        message: "Taking a quick trip around the patient's case history. Be right back!",
      })
    } else if (expertClinicalNotesData?.askStellaConversation === "mid") {
      setShowAskstellaPreviewMessage({
        show: false,
        message: "We are loading your data. Please get some coffee and refresh the page to check back.",
      })
    } else if (expertClinicalNotesData?.askStellaConversation === "end") {
      setShowAskstellaPreviewMessage({
        show: false,
        message: "I'm back and ready to assist you!",
      })
    }

  }, [expertClinicalNotesData?.askStellaConversation, currentSubTab])


  useEffect(() => {
    if (expertClinicalNotesData?.data?.submitted) {
      setCurrentTab("stella");
    }
  }, [expertClinicalNotesData?.data?.submitted]);

  useEffect(() => {
    scrollChatToBottom();
  }, [chatHistory]);



  const scrollChatToBottom = () => {
    const chatElement = chatContainerRef.current;
    if (chatElement) {
      // Use setTimeout to allow DOM updates to complete before scrolling
      setTimeout(() => {
        chatElement.scrollTop = chatElement.scrollHeight;
      }, 0);
    }
  };

  const payload = {
    user_id: expertClinicalNotesData?.headerData?.user_id,
    question: transcriptValue,
    time_stamp: "1711896754",
    classify: isAusDomain() ? "aus" : "",
    headers: {
      user_id: expertClinicalNotesData?.headerData?.user_id,
      session_id: expertClinicalNotesData?.headerData?.session_id,
      registered_id: "1",
    },
    max_tokens: 512,
    temperature: 0,
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
    // console.log(newValue);
    if (newValue === 0) {
      setCurrentTab("graph");
    } else if (newValue === 1) {
      setCurrentTab("assistant");
    } else if (newValue === 2) {
      setCurrentTab("stella");
    }
  };

  const fetchStellaConversation = async () => {
    try {
      const response = await fetch("https://rag.uwc.world/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok: ' + response.statusText);
      }
      const resp = await response.json();


      const currentTime = new Date();
      //console.log("resp", resp);
      const newStellaResponse = {
        response: "stella",
        message: resp?.response,
        timestamp: currentTime,
      };

      setChatHistory((prevHistory) => {
        // Remove the last message

        const updatedHistory = prevHistory.slice(0, -1);
        // Add the new message
        return [...updatedHistory, newStellaResponse];
      });
    } catch (error) {
      console.log(error);
    }
  };

  // speech recognization new implementation

  const [isRecording, setIsRecording] = useState(false);
  const [transcription, setTranscription] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: isSafari() ? "audio/mp4" : "audio/webm;codecs=opus", });
    mediaRecorderRef.current.ondataavailable = event => {
      audioChunksRef.current.push(event.data);
    };
    mediaRecorderRef.current.onstop = async () => {
      setTranscription(true);
      const audioBlob = new Blob(audioChunksRef.current, { type: isSafari() ? "audio/mp4" : "audio/webm;codecs=opus", });
      audioChunksRef.current = [];
      await sendAudioToApi(audioBlob);
      // Send the new audioBlob after stopping the recording
    };
    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  const sendAudioToApi = async (blob) => {
    try {
      const base64String = await convertBlobToBase64(blob);
      const formattedBase64 = isSafari()
        ? String(base64String).split("data:audio/mp4;base64,")[1]
        : String(base64String).split("data:audio/webm;codecs=opus;base64,")[1];
      if (formattedBase64) {
        const whisperTranscription = await fetchSTTWhisper(
          JSON.stringify({ base64_audio: formattedBase64 })
        );
        setTranscriptValue(transcriptValue + " " + whisperTranscription);
        setTranscription(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // speech recognization end  implementation

  const sendMessage = () => {
    if (!expertClinicalNotesData?.headerData?.user_id) return
    if (files.length > 0 || transcriptValue?.trim() === "") return;
    const newUserMessage = {
      response: "user",
      message: transcriptValue,
      timestamp: new Date(),
    };
    setChatHistory((prevHistory) => [
      ...prevHistory,
      newUserMessage,
      { response: "stella", message: "####", timestamp: null },
    ]);
    setTranscriptValue("");
    fetchStellaConversation();
  };

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }
  };

  const handleCopyClick = (text) => {
    const message = text; // Adjust this if your message format needs parsing
    if (message) {
      copyTextToClipboard(message)
        .then(() =>
          enqueueSnackbar("Text copied!", {
            variant: "success",
          })
        )
        .catch((err) => {
          enqueueSnackbar("Failed to copy text ", {
            variant: "error",
          });
          console.error("Failed to copy text: ", err);
        });
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = [...event.target.files];
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    console.log(selectedFiles);
    event.target.value = null;
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getFileType = (file) => {
    if (file.type.startsWith("image/")) {
      return "image";
    } else if (file.type.startsWith("video/")) {
      return "video";
    } else if (file.type === "application/pdf") {
      return "pdf";
    } else {
      return "other";
    }
  };

  const setAudioRequestData = (blob) => {
    //console.log("BLOB", blob);
  };
  function getCurrentTime(date) {
    var now = new Date(date);
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    minutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if minutes < 10
    var timeString = hours + ":" + minutes + " " + ampm;
    return timeString;
  }

  const handleMultipleFileUploadTOOCR = async () => {
    if (files.length < 1) {
      return;
    }
    const filesArr = [...files]; // Clone the files array if necessary
    const newUserMessage = {
      response: "user",
      message: "",
      timestamp: new Date(),
      files: filesArr,
    };
    setChatHistory((prevHistory) => [...prevHistory, newUserMessage]);
    setFiles([]); // Clear the files state after adding to chat history

    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];
    let imageConcatText = ""; // Initialize the variable to concatenate text from all images

    const promises = filesArr.map((file, index) => {
      if (file.size > 20 * 1024 * 1024) {
        enqueueSnackbar("File size should be less than 20 MB", {
          variant: "error",
        });
        return { error: "File size too large", index };
      }

      if (!allowedTypes.includes(file.type)) {
        enqueueSnackbar(
          `File type not supported. Allowed types are ${allowedTypes.join(
            ", "
          )}`,
          { variant: "error" }
        );
        return { error: "File type not supported", index };
      }

      const dirName = moment().format("MMMM_YYYY");
      const storageRef = ref(storage, `${dirName}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve) => {
        uploadTask.on(
          "state_changed",
          null, // Progress is handled optionally as previously described
          (error) => {
            console.log(`Upload error for file index ${index}:`, error);
            resolve({ error: error.message, index });
          },
          async () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => {
                LAMA_OCR_Document(url)
                  .then((res) => {
                    if (res?.error) {
                      enqueueSnackbar(res?.message, { variant: "info" });
                      return;
                    }
                    const transcript = res.response;
                    imageConcatText += `Document ${index + 1}: ${transcript}\n`;
                    resolve({
                      transcript: `Document ${index + 1}: ${transcript}`,
                    });
                  })
                  .catch((error) => {
                    console.log(`OCR error for file index ${index}:`, error);
                    resolve({ error: error.message, index });
                  });
              })
              .catch((error) => {
                console.log(
                  `Download URL error for file index ${index}:`,
                  error
                );
                resolve({ error: error.message, index });
              });
          }
        );
      });
    });

    try {
      const results = await Promise.all(promises);
      const failed = results.filter((result) => result.error);
      if (failed.length > 0) {
        failed.forEach((fail) => {
          console.log(
            `Handling failed operation for file index ${fail.index}:`,
            fail.error
          );
        });
      }
      await InsertRagContext({
        transcript: imageConcatText,
        ...expertClinicalNotesData?.headerData,
      }).then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          return;
        }

      })
      expertClinicalNotesData.socketRef.current.send(JSON.stringify(
        {
          _id: expertClinicalNotesData?.headerData?.session_id,
          raw_data: imageConcatText,
          user_id: expertClinicalNotesData?.headerData?.user_id,
          expert_id: expertClinicalNotesData?.headerData?.expert_id,
          state: "In Progress"
        }
      ));
      enqueueSnackbar("File(s) uploaded successfully.", { variant: "success" });
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
    imageConcatText = ""; // Clear the concatenated text after processing
  };
  const [assistantLoading, setAssistantLoading] = useState(false);
  const handleUpdateAssitant = async () => {
    setAssistantLoading(true);
    const payload = {
      ...expertClinicalNotesData?.headerData,
      assistant: {},
      drugs:
        expertClinicalNotesData?.data?.progress_note?.medicines
          ?.prescribed_medicines,
      labtest: expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test,
      disease: "",
    };
    if (expertClinicalNotesData.socketRef.current) {
      // console.log("Sending data:", data);
      expertClinicalNotesData.socketRef.current.send(
        JSON.stringify({
          action_type: "alert_regenerated",
          details: {
            alert_name: "Assistant"
          },
          user_id: expertClinicalNotesData?.headerData?.user_id,
          expert_id: expertClinicalNotesData?.headerData?.expert_id,
        }));

    }

    HandleBlurForAssistant(payload).then((res) => {
      if (res?.error) {
        enqueueSnackbar(res?.message, { variant: "info" });
        setAssistantLoading(false);
        return;
      }
      setAssistantLoading(false);
    }).catch((err) => {
      console.error(err); // Optionally log the error internally here as well
    });
  };
  return (
    <div>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="assistant--Modal"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={show}>
          <Box sx={style}>
            <div
              style={{ background: "#F5F5F5", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
              className="SessionDashboard--TabsContainer d-flex align-items-center justify-content-between px-md-4"
            >
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className="SessionDashboard--Tabs"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab id="graph" label="Graph" />
                {!expertClinicalNotesData?.data?.submitted && (
                  <Tab id="assistant" label="Assistant" />
                )}
                <Tab id="ask_stella" label={process.env.REACT_APP_CLIENT_DISPLAY_NAME?.includes("Ask") ? process.env.REACT_APP_CLIENT_DISPLAY_NAME : `Ask ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}`} />
              </Tabs>

              <IconButton onClick={close} aria-label="delete">
                <Close />
              </IconButton>
            </div>

            {/* </div> */}
            {/* Tab Content */}
            <div className="content p-0 ">
              {/* Graph panel */}
              <CustomTabPanel value={selectedTab} index={0}>
                <GraphVisualization currentTab={"graph"} />
              </CustomTabPanel>

              {/* Medicine panel */}
              <CustomTabPanel
                value={selectedTab}
                index={expertClinicalNotesData?.data?.submitted ? -1 : 1}
              >
                <div>
                  <div style={{ overflowY: "auto" }} className="tabDetail-Holder d-flex align-items-center  justify-content-between gap-2">
                    <div style={{ minWidth: "700px" }} className="d-flex w-100 align-items-center justify-content-between gap-2">
                      <div className="d-flex align-items-center gap-2">
                        {" "}
                        {tabs?.map((item, index) => (
                          <div
                            key={index}
                            className="tabDetail d-flex align-items-center gap-2 rounded-5"
                            style={{
                              backgroundColor:
                                currentSubTab === index
                                  ? item?.fontColor
                                  : item?.bgColor,
                              color:
                                currentSubTab === index
                                  ? "#fff"
                                  : item?.fontColor,
                              fill:
                                currentSubTab === index
                                  ? "#fff"
                                  : item?.fontColor,
                              cursor: "pointer",
                            }}
                            id={`assistant_${item?.tabName}`}
                            onClick={() => setCurrentSubContent(index)}
                          >
                            {item?.icon} {item?.tabName}
                            <span
                              className="  rounded-pill"
                              style={{
                                backgroundColor:
                                  currentSubTab === index
                                    ? "#fff"
                                    : item?.fontColor,
                                color:
                                  currentSubTab === index
                                    ? item?.fontColor
                                    : "#fff",
                                width: item?.value > 99 ? "30px" : "20px",
                                height: "20px",
                                paddingTop: "2px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "5px",
                                fontSize: "14px",
                              }}
                            >
                              {item?.value > 99 ? "99+" : (item?.value || 0)}
                            </span>
                          </div>
                        ))}
                      </div>




                      <Button
                        onClick={handleUpdateAssitant}
                        style={{
                          color: "var(--primary-color)",
                          background: "var(--primary-accent-color)",
                        }}
                        id="update_assistant"
                        className=" rounded-2 font-inter font-w600 border-0"
                      >
                        <Cached
                          className={`${assistantLoading ? "animate-spin" : ""
                            } me-2`}
                        />
                        {expertClinicalNotesData?.data?.assistant?.diagnostics
                          ?.alert_number +
                          expertClinicalNotesData?.data?.assistant?.medications
                            ?.alert_number +
                          expertClinicalNotesData?.data?.assistant?.alerts
                            ?.alert_number <
                          0
                          ? "Generate"
                          : "Regenerate"}
                      </Button>
                    </div>

                  </div>
                  <div className="innerTab--section">
                    {currentSubTab === 0 && (
                      <div className="d-flex flex-column gap-2 px-lg-3 p-1 innerTab--container">
                        {/* {expertClinicalNotesData?.data?.assistant?.alerts
                          ?.drug_drug_interaction?.length > 0 && (
                          <DrugAssistantItem />
                        )} */}

                        {Object.keys(
                          expertClinicalNotesData?.data?.assistant?.alerts || {}
                        ).map(
                          (key) =>
                            key !== "alert_number" &&
                            // key !== "drug_drug_interaction" &&
                            expertClinicalNotesData?.data?.assistant?.alerts[
                              key
                            ]?.filter((it) => !Array.isArray(it))?.map((item, index) => (
                              <AssistantItem
                                key={index}
                                heading={key}
                                label={"alerts"}
                                item={item}
                                index={index}
                              />
                            ))
                        )}

                        {expertClinicalNotesData?.data?.assistant?.alerts?.alert_number === 0 &&
                          <div className="w-100 h-100 mb-5 d-flex flex-column gap-4 justify-content-center align-items-center">
                            <NotificationsOffRounded className=" text-muted fs-1" />
                            You have no Alerts at this time. We'll update this space when we have an Alert for you.
                          </div>
                        }
                      </div>
                    )}
                    {currentSubTab === 1 && (
                      <div className="d-flex  flex-column gap-2 px-3 innerTab--container">
                        {Object.keys(
                          expertClinicalNotesData?.data?.assistant
                            ?.medications || {}
                        ).map(
                          (key) =>
                            key !== "alert_number" &&
                            expertClinicalNotesData?.data?.assistant?.medications[
                              key
                            ]?.filter((it) => !Array.isArray(it))?.map((item, index) => (
                              <AssistantItem
                                key={index}
                                heading={key}
                                label={"medications"}
                                item={item}
                                index={index}
                              />
                            ))
                        )}
                        {expertClinicalNotesData?.data?.assistant?.medications?.alert_number === 0 &&
                          <div className="w-100 h-100 mb-5 d-flex flex-column gap-4 justify-content-center align-items-center">
                            <NotificationsOffRounded className=" text-muted fs-1" />
                            You have no Alerts at this time. We'll update this space when we have an Alert for you.
                          </div>
                        }
                      </div>
                    )}

                    {currentSubTab === 2 && (
                      <div className="d-flex flex-column gap-2 px-3 innerTab--container">
                        {Object.keys(
                          expertClinicalNotesData?.data?.assistant
                            ?.diagnostics || {}
                        ).map(
                          (key) =>
                            key !== "alert_number" &&
                            expertClinicalNotesData?.data?.assistant?.diagnostics[
                              key
                            ]?.filter((it) => !Array.isArray(it))?.map((item, index) => (
                              <AssistantItem
                                key={index}
                                heading={key}
                                label={"diagnostics"}
                                item={item}
                                index={index}
                              />
                            ))
                        )}
                        {expertClinicalNotesData?.data?.assistant?.diagnostics?.alert_number === 0 &&
                          <div className="w-100 h-100 mb-5 d-flex flex-column gap-4 justify-content-center align-items-center">
                            <NotificationsOffRounded className=" text-muted fs-1" />
                            You have no Alerts at this time. We'll update this space when we have an Alert for you.
                          </div>
                        }
                      </div>
                    )}
                  </div>
                </div>
              </CustomTabPanel>

              {/* Ask stella panel */}
              <CustomTabPanel
                value={selectedTab}
                index={expertClinicalNotesData?.data?.submitted ? 1 : 2}
              >
                {
                  <div className="chat--container p-1 p-md-3" ref={chatContainerRef}>

                    <div
                      className={
                        "receivedChat"
                      }
                    >
                      <div
                        className="d-flex  gap-2 "
                        style={{ width: "90%" }}
                      >
                        <img
                          src="https://images.unitedwecare.com/odoo/open/faces/01.webp"
                          className="rounded-circle  "
                          alt="stella"
                          style={{ width: 34, height: 34 }}
                        />
                        <div>
                          <div className="textContainer   p-3">
                            <p className="fw-bold m-0">
                              {process.env.REACT_APP_CLIENT_DISPLAY_NAME?.includes("Ask") ? process.env.REACT_APP_CLIENT_DISPLAY_NAME : `Ask ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}`} - Your Clinical Co-Pilot
                            </p>
                            <div>
                              {InitialMessage}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    {/* <div
                      className={
                        "receivedChat"
                      }
                    >
                      <div
                        className="d-flex  gap-2 "
                        style={{ width: "90%" }}
                      >
                        <img
                          src="https://images.unitedwecare.com/odoo/open/faces/01.webp"
                          className="rounded-circle  "
                          alt="stella"
                          style={{ width: 34, height: 34 }}
                        />
                        <div>
                          <div className="textContainer   p-3">
                            <p className="fw-bold">
                              Stella - Your Clinical Co-Pilot
                            </p>
                            <div>
                              {showAskstellaPreviewMessage?.message}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div> */}

                    {chatHistory.map((data, index) => {

                      if (data.response === "user") {
                        return (
                          <div
                            className="sentChat d-flex flex-column align-items-end"
                            key={index}
                          >
                            <div
                              className="d-flex  justify-content-end gap-2"
                              style={{ width: "90%" }}
                            >
                              <div>
                                <div className="textContainer  d-flex flex-column  align-items-end p-3">
                                  <p className="fw-bold m-0">
                                    {expertClinicalNotesData?.expert_info?.Name?.replace(
                                      /\b\w/g,
                                      (c) => c.toUpperCase()
                                    )}
                                  </p>
                                  <p>{data?.message}</p>
                                  {data?.files?.length > 0 && (
                                    <div>
                                      {data?.files?.map((file, index) => (
                                        <div
                                          key={index}
                                          className="d-flex align-items-center  gap-2"
                                        >
                                          <AttachFile />
                                          <div>
                                            <p className="fw-semibold">
                                              {(file?.name &&
                                                file.name.length > 20
                                                ? file.name.substring(0, 17) +
                                                "..."
                                                : file.name.substring(
                                                  0,
                                                  file?.name?.lastIndexOf(".")
                                                )) +
                                                (file?.name?.lastIndexOf(
                                                  "."
                                                ) !== -1
                                                  ? file.name.substring(
                                                    file.name.lastIndexOf(".")
                                                  )
                                                  : "")}
                                            </p>

                                            <p>
                                              {Math.round(file?.size / 1024)} KB
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                                <p className=" text-end">
                                  {getCurrentTime(data?.timestamp)}
                                </p>
                              </div>
                              <img
                                src={profileImage}
                                width={34}
                                height={34}
                                alt=""
                                className="rounded-circle"
                              />
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <>
                            <div
                              className={
                                data?.message === "####"
                                  ? "assistant-message-response rounded-3"
                                  : "receivedChat"
                              }
                            >
                              {data?.message === "####" ? (
                                <div id="wave-animation">
                                  <div
                                    id="loading-bubble"
                                    className="bg-muted dark:bg-muted-dark"
                                  >
                                    <div className="spinner">
                                      <div className="bounce1 bg-slate-400/50 dark:bg-slate-200"></div>
                                      <div className="bounce2 bg-slate-400/50 dark:bg-slate-200"></div>
                                      <div className="bounce3 bg-slate-400/50 dark:bg-slate-200"></div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="d-flex  gap-2 "
                                  style={{ width: "90%" }}
                                >
                                  <img
                                    src="https://images.unitedwecare.com/odoo/open/faces/01.webp"
                                    className="rounded-circle  "
                                    alt="stella"
                                    style={{ width: 34, height: 34 }}
                                  />
                                  <div>
                                    <div className="textContainer   p-3">
                                      <p className="fw-bold m-0">
                                        {process.env.REACT_APP_CLIENT_DISPLAY_NAME} - Your Clinical Co-Pilot
                                      </p>

                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: `<div>${data?.message
                                            .split('\n')
                                            .map(line => {
                                              const sanitizedLine = line.replace(
                                                /[+*]/g,
                                                ""
                                              );
                                              if (line.startsWith('**')) {
                                                return `<p style="font-weight:bold;">${sanitizedLine}</p>`;
                                              } else if (line.startsWith('*')) {
                                                return `<li>${sanitizedLine.slice(0)}</li>`;
                                              } else if (/^\d+\./.test(line)) {
                                                return `<p>${sanitizedLine}</p>`;
                                              } else {
                                                return sanitizedLine;
                                              }
                                            })
                                            .join('<br/>')}</div>`,
                                        }}
                                      />




                                      <div className="d-flex align-items-center gap-2 mt-3 justify-content-end">
                                        {/* <IconButton
                                onClick={() => setIsActive(!isActive)}
                              >
                                {isActive ? (
                                  <ThumbDownIcon sx={{ color: "#4B0082" }} />
                                ) : (
                                  <ThumbDownOutlinedIcon
                                    sx={{ color: "#8D76AF" }}
                                  />
                                )}
                              </IconButton> */}
                                        {/* <IconButton>
                                  <RefreshOutlinedIcon
                                    sx={{ color: "#8D76AF" }}
                                  />
                                </IconButton> */}
                                        {data?.message &&
                                          data?.message !== "####" && (
                                            <IconButton>
                                              <ContentCopyOutlined
                                                sx={{ color: "var(--primary-color)" }}
                                                onClick={() => {
                                                  handleCopyClick(
                                                    data?.message
                                                  );
                                                }}
                                              />
                                            </IconButton>
                                          )}
                                      </div>
                                    </div>
                                    {data?.message !== "####" && (
                                      <p className=" text-end">
                                        {getCurrentTime(data?.timestamp)}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      }
                    })}

                    <div className="chatInputContainer  p-0 px-1">
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {files.map((file, index) => (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              margin: "5px",
                              maxWidth: "fit-content",
                            }}
                          >
                            {getFileType(file) === "image" && (
                              <img
                                src={URL.createObjectURL(file)}
                                alt="Uploaded"
                                width="100"
                                height="100"
                              />
                            )}
                            {getFileType(file) === "video" && (
                              <video
                                src={URL.createObjectURL(file)}
                                width="100"
                                height="100"
                                controls
                              />
                            )}
                            {getFileType(file) === "pdf" && (
                              <div
                                style={{ border: "1px solid #CBCBCB" }}
                                className="w-100 rounded-2 p-2"
                              >
                                {file.name}
                              </div>
                            )}
                            <IconButton
                              style={{
                                position: "absolute",
                                top: "-5px",
                                right: "-5px",
                                color: "white",
                                backgroundColor: "var(--primary-color)",
                                width: "24px",
                                height: "24px",
                              }}
                              onClick={() => removeFile(index)}
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          </div>
                        ))}
                      </div>
                      <Paper
                        component="form"
                        sx={{
                          display: "flex",
                          alignItems: "flex-end"
                        }}
                        className="w-100 py-1 shadow-none"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <label htmlFor="file-upload">
                          <IconButton
                            sx={{ p: "10px" }}
                            aria-label="menu"
                            component="span"
                            id="ask_stella_attach_file"
                          >
                            <AttachFile sx={{ color: "var(--primary-color)", transform: "rotate(45deg)" }} />
                          </IconButton>
                          <input
                            id="file-upload"
                            type="file"
                            accept="image/*,video/*,.pdf"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            disabled={chatHistory[chatHistory.length - 1]?.message === "####" || showAskstellaPreviewMessage?.show || transcription}
                            multiple
                          />
                        </label>
                        <Divider
                          sx={{
                            height: 35,
                            m: 0.5,
                            backgroundColor: "#CBCBCB",
                          }}
                          orientation="vertical"
                        />    {transcription && <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: "relative",

                          height: "45px",
                        }}>
                          <style>
                            {`
          @keyframes ripple {
            0% {
              transform: scale(0.5);
              opacity: 1;
            }
            100% {
              transform: scale(3);
              opacity: 0;
            }
          }
        `}
                          </style>

                          {/* Central green dot */}
                          <div style={{
                            position: 'relative',
                            width: '10px',
                            height: '10px',
                            backgroundColor: 'green',
                            borderRadius: '50%',
                            display: "flex",
                            justifyContent: "center"
                          }}>

                            {/* Ripple effect */}
                            <div style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'green',
                              borderRadius: '50%',
                              transform: 'translate(-50%, -50%)',
                              animation: 'ripple 1.5s infinite',
                              opacity: 0.5,
                            }}></div>

                            {/* Second ripple for staggered effect */}
                            <div style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'green',
                              borderRadius: '50%',
                              transform: 'translate(-50%, -50%)',
                              animation: 'ripple 1.5s infinite 0.75s', // Delay for staggered ripple
                              opacity: 0.5,
                            }}></div>

                          </div>
                        </div>}
                        <TextareaAutosize

                          minRows={1}
                          maxRows={3}
                          disabled={chatHistory[chatHistory.length - 1]?.message === "####" || showAskstellaPreviewMessage?.show || transcription}
                          placeholder={`${process.env.REACT_APP_CLIENT_DISPLAY_NAME?.includes("Ask") ? process.env.REACT_APP_CLIENT_DISPLAY_NAME : `Ask ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}`} Anything...`}
                          aria-label={`${process.env.REACT_APP_CLIENT_DISPLAY_NAME?.includes("Ask") ? process.env.REACT_APP_CLIENT_DISPLAY_NAME : `Ask ${process.env.REACT_APP_CLIENT_DISPLAY_NAME}`} Anything...`}
                          value={transcription ? "Transcribing..." : transcriptValue}
                          onChange={(e) => setTranscriptValue(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              const trimmedValue = transcriptValue.trim();
                              if (trimmedValue !== "") {
                                e.preventDefault();
                                sendMessage();
                                handleMultipleFileUploadTOOCR();
                              }
                            }
                          }}
                          style={{
                            width: "100%",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            border: "none",
                            resize: "none",
                            fontSize: "1rem",
                            lineHeight: "1.5",
                            marginBottom: "3px"
                          }}
                        />




                        <div className="mx-2">{isRecording ? (
                          <IconButton
                            disabled={chatHistory[chatHistory.length - 1]?.message === "####" || showAskstellaPreviewMessage?.show || transcription}
                            className="StartTranscription--Button__ALT"
                            style={{
                              zIndex: "100",
                            }}
                            sx={{ padding: 0 }}
                            id="ask_stella_stop_recording"
                            onClick={stopRecording}
                          >
                            <div
                              style={{
                                background: "#dfe3ff",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Stop style={{ color: "var(--primary-color)" }} />
                            </div>
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={chatHistory[chatHistory.length - 1]?.message === "####" || showAskstellaPreviewMessage?.show || transcription}
                            className="StartTranscription--Button "
                            sx={{ padding: 0 }}
                            onClick={startRecording}
                            id="ask_stella_start_recording"
                          >
                            <div
                              style={{
                                background: "var(--primary-color)",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Mic style={{ color: "#FFF" }} />
                            </div>
                          </IconButton>
                        )}</div>
                        <IconButton
                          type="button"
                          sx={{
                            padding: "2px",
                            height: "45px",
                            aspectRatio: "1/1",
                          }}
                          className="PrimaryCTA"
                          id="ask_stella_send_message"
                          disabled={chatHistory[chatHistory.length - 1]?.message === "####" || showAskstellaPreviewMessage?.show || transcription}
                          onClick={() => {
                            sendMessage();
                            handleMultipleFileUploadTOOCR();
                          }}
                        >
                          <ArrowRightAlt fontSize="medium" />
                        </IconButton>
                      </Paper>
                    </div>
                  </div>
                }
              </CustomTabPanel>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default AssistantModal;
