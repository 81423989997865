import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// ICONS
import { CheckBoxOutlined } from "@mui/icons-material";

const ClientScreenerDetailsTable = ({ options, responses, totalScore, score, name }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 2,
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        margin: "0 auto",
        maxWidth: "90%",
      }}
    >
      {/* Table Header */}
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          padding: 2,
          backgroundColor: "var(--primary-color-opacity30)",
          color: "var(--primary-color)",
        }}
      >
        {name}
      </Typography>

      {/* Table */}
      <Table sx={{ minWidth: 650 }} aria-label="Screener Details Table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "var(--primary-color-opacity20)",
            }}
          >
            <TableCell
              sx={{
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                fontWeight: "bold",
                textAlign: "center",
                width: "40%",
              }}
            >
              Question
            </TableCell>
            {options?.map((item) => (
              <TableCell
                key={item?.sequence}
                align="center"
                sx={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  fontWeight: "bold",
                }}
              >
                {item?.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Questions and Responses */}
          {responses?.map((res, i) => (
            <TableRow
              key={i}
              sx={{
                "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                "&:hover": { backgroundColor: "#f1f1f1" },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                <Typography
                  sx={{ fontWeight: 500, display: "flex", alignItems: "center" }}
                >
                  {i + 1}. {res?.question}
                </Typography>
              </TableCell>
              {options?.map((op) => (
                <TableCell align="center" sx={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }} key={op.sequence}>
                  {+res?.response === +op?.sequence && (
                    <CheckBoxOutlined
                      sx={{

                        color: "var(--primary-color)",
                        fontSize: "26px",
                      }}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}

          {/* Total Score Row */}
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: "0 !important" },
            }}
          >
            <TableCell
              component="th"
              scope="row"
              align="right"
              sx={{
                background: "#FFF",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Total Score
            </TableCell>
            {options?.map((op, index) => (
              <TableCell
                align="center"
                sx={{
                  background: "var(--primary-color-opacity30)",
                  fontWeight: "bold",
                }}
                key={op.sequence}
              >
                {index === Math.floor(options.length / 2)
                  ? `${score}/${totalScore}`
                  : null}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientScreenerDetailsTable;
