import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context";

// Components
import TextEditor from "./Components/TextEditor";
import NotesSkeltonLoader from "./Skeletons/NotesSkeltonLoader";
import CNReviewBar from "./CNReviewBar";

// MUI
import {
  Autocomplete,
  Button,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextEditorCommonSkeleton from "./Skeletons/TextEditorCommonSkeleton";
import HideUnhideHandler from "./Components/HideUnhideHandler";
import FollowUpDateCaclulator from "./Components/FollowUpDateCaclulator";
import FollowUpdateSkeleton from "./Skeletons/FollowUpdateSkeleton";
import { useSnackbar } from "notistack";
import {
  autocompleteLabTest,
  HandleBlurForAssistant,
} from "./ClinicalNotesAPI/ClinicalNoteAPIManager";
import { generateRandomId } from "../../core/commonFun";
import PrescriptionMedicineUpdate from "./Components/PrescriptionMedicineUpdate";
import RecommendedLabTestDialog from "./Dialogs/RecommendedLabTestDialog";
import LabtestsSkeleton from "./Skeletons/LabtestsSkeleton";
import {
  AddCircle,
  Delete,
  HelpOutline,
  InfoOutlined,
} from "@mui/icons-material";
import { hasTextChanged, isDeepEqual } from "./lib/utils";
import dayjs from "dayjs";

function CNSessionNote() {
  const {
    expertClinicalNotesData,
    setExpertClinicalNotesData,
    savedCopy,
    setSavedCopy,
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [recommendedLabTest, setRecommendedLabTest] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [sendData, setSendData] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("");
  const [recommendedTestModal, setRecommendedTestModal] = useState({
    view: false,
    searchTerm: "",
    label: "",
  });

  useEffect(() => {
    if (expertClinicalNotesData?.data?.progress_note) {
      setIsLoading(false);
      Object.keys(expertClinicalNotesData.data.progress_note).forEach((key) => {
        const progressNote = expertClinicalNotesData.data.progress_note[key];
        if (progressNote?.options?.length === 0) {
          // Initialize options array with default options
          expertClinicalNotesData.data.progress_note[key].options = [
            { option_text: "Yes", selected: "false" },
            { option_text: "No", selected: "true" }, // Changed "N0" to "No"
          ];
        }
      });
    }
  }, [expertClinicalNotesData]);

  useEffect(() => {
    setExpertClinicalNotesData({
      ...expertClinicalNotesData,
      data: {
        ...expertClinicalNotesData?.data,
        progress_note: {
          ...expertClinicalNotesData?.data?.progress_note,
          medicines: {
            ...expertClinicalNotesData?.data?.progress_note?.medicines,
            prescribed_medicines:
              expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                (item) =>
                  item?.drug !== "" &&
                  item?.drug != null &&
                  item?.drug !== undefined
              ),
          },
        },
        lab_test: {
          ...expertClinicalNotesData?.data?.lab_test,
          prescribed_lab_test:
            expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
              (item) =>
                item?.test_name !== "" &&
                item?.test_name != null &&
                item?.test_name !== undefined
            ),
        },
      },
    });

    // setSendData(true);
  }, []);

  useEffect(() => {
    if (!searchTerm) return;
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm && searchType === "lab_test") {
      autocompleteLabTest(debouncedSearchTerm)
        .then((response) => {
          if (response.status === "success") {
            setRecommendedLabTest(response.data);
          } else {
            setRecommendedLabTest([]);
          }
        })
        .catch((err) => {
          console.error(err); // Optionally log the error internally here as well
        });
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    if (sendData) {
      const data = JSON.stringify({
        header: expertClinicalNotesData?.headerData,
        session_data: {
          ...expertClinicalNotesData?.data,
          progress_note: {
            ...expertClinicalNotesData?.data?.progress_note,
            medicines: {
              ...expertClinicalNotesData?.data?.progress_note?.medicines,
              prescribed_medicines:
                expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                  (item) =>
                    item?.drug !== "" &&
                    item?.drug != null &&
                    item?.drug !== undefined
                ),
            },
          },
          lab_test: {
            ...expertClinicalNotesData?.data?.lab_test,
            prescribed_lab_test:
              expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
                (item) =>
                  item?.test_name !== "" &&
                  item?.test_name != null &&
                  item?.test_name !== undefined
              ),
          },
        },
      });

      expertClinicalNotesData?.socketRef?.current?.send(data);
      setExpertClinicalNotesData({
        ...expertClinicalNotesData,
        data: {
          ...expertClinicalNotesData?.data,
          progress_note: {
            ...expertClinicalNotesData?.data?.progress_note,
            medicines: {
              ...expertClinicalNotesData?.data?.progress_note?.medicines,
              prescribed_medicines:
                expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                  (item) =>
                    item?.drug !== "" &&
                    item?.drug != null &&
                    item?.drug !== undefined
                ),
            },
          },
          lab_test: {
            ...expertClinicalNotesData?.data?.lab_test,
            prescribed_lab_test:
              expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
                (item) =>
                  item?.test_name !== "" &&
                  item?.test_name != null &&
                  item?.test_name !== undefined
              ),
          },
        },
      });
      setSendData(false);
    }
  }, [sendData]);

  const handleInputChangeLab = (rowIndex, field, value) => {
    setRecommendedLabTest([]);
    let updatedRows;
    if (field === "time_period") {
      updatedRows =
        expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.map(
          (row, index) =>
            index === rowIndex
              ? {
                  ...row,
                  time_to_result: `${
                    row?.time_to_result?.split(" ")?.[0] || ""
                  } ${value}`,
                }
              : row
        );
    } else if (field === "time_to_result") {
      updatedRows =
        expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.map(
          (row, index) =>
            index === rowIndex
              ? {
                  ...row,
                  [field]: `${value.replace(/\D/g, "")} ${
                    row?.time_to_result?.split(" ")?.[1] || ""
                  }`,
                }
              : row
        );
    } else {
      updatedRows =
        expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.map(
          (row, index) =>
            index === rowIndex ? { ...row, [field]: value } : row
        );
    }
    if (field === "test_name") {
      setSearchTerm(value);
      setSearchType("lab_test");
    }

    const list = new Set(savedCopy?.list);

    if (!isDeepEqual(updatedRows, savedCopy["lab_test"])) {
      list.add("lab_test");
      setSavedCopy((prevState) => ({
        ...prevState,
        list,
      }));
      console.log(list);
    } else {
      list.delete("lab_test");
      setSavedCopy((prevState) => ({
        ...prevState,
        list,
      }));
    }

    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      data: {
        ...prevData?.data,
        lab_test: {
          ...prevData?.data?.lab_test,
          prescribed_lab_test: updatedRows,
        },
      },
    }));
  };

  const handleDeleteLabTest = (rowIndex) => {
    const updatedRows =
      expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
        (_, i) => i !== rowIndex
      );
    const list = new Set(savedCopy?.list);

    if (!isDeepEqual(updatedRows, savedCopy["lab_test"])) {
      list.add("lab_test");
      setSavedCopy((prevState) => ({
        ...prevState,
        list,
      }));
      console.log(list);
    } else {
      list.delete("lab_test");
      setSavedCopy((prevState) => ({
        ...prevState,
        list,
      }));
    }
    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      data: {
        ...prevData?.data,
        lab_test: {
          ...prevData?.data?.lab_test,

          prescribed_lab_test: updatedRows,
        },
      },
    }));
    setSendData(true);
  };

  const renderTextEditor = (key, labelText, defaultValue, tooltip) => {
    return (
      <div
        className={`w-100 ${
          !expertClinicalNotesData?.data?.template?.some(
            (item) => item.name === key
          ) &&
          !expertClinicalNotesData?.editTemplate &&
          "d-none"
        }`}
      >
        <h6 className="d-flex  gap-2 align-items-center">
          {labelText}
          {/* {" Completed:" +
            expertClinicalNotesData?.data?.progress_note?.[key]?.completed +
            " total:" +
            expertClinicalNotesData?.data?.progress_note?.[key]?.total +
            " current:" +
            expertClinicalNotesData?.data?.progress_note?.[key]?.current} */}
          <Tooltip
            arrow
            title={
              <p className="m-0" style={{ fontSize: "14px", color: "white" }}>
                {tooltip}
              </p>
            }
            placement="top-start"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.black",
                  "& .MuiTooltip-arrow": {
                    color: "common.black",
                  },
                  color: "common.white",
                  padding: "10px",
                },
              },
            }}
          >
            {" "}
            <InfoOutlined sx={{ fontSize: "16px", color: "black" }} />{" "}
          </Tooltip>
          <HideUnhideHandler
            status={expertClinicalNotesData?.data?.template?.some(
              (item) => item.name === key
            )}
            field={key}
          />
        </h6>

        <div
          className={`${
            !expertClinicalNotesData?.data?.template?.some(
              (item) => item.name === key
            ) && "d-none"
          } w-100`}
        >
          {expertClinicalNotesData?.data?.progress_note?.[key]?.display ===
          "false" ? (
            <NotesSkeltonLoader />
          ) : (
            <>
              {!expertClinicalNotesData?.data?.progress_note?.[key] ? (
                <TextEditorCommonSkeleton />
              ) : (
                <>
                  <TextEditor
                    initialText={
                      expertClinicalNotesData?.data?.progress_note?.[key]
                        ?.text || defaultValue
                    }
                    disabled={
                      expertClinicalNotesData?.data?.submitted ||
                      expertClinicalNotesData?.editTemplate
                    }
                    htmlText={(text) => {
                      setExpertClinicalNotesData((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState?.data,
                          progress_note: {
                            ...prevState?.data?.progress_note,
                            [key]: {
                              ...prevState?.data?.progress_note[key],
                              text: text,

                              is_edited:
                                text != null &&
                                text?.trim() !== "" &&
                                text?.replace(/&nbsp;/g, "") !== "<p></p>\n"
                                  ? "true"
                                  : null,
                            },
                          },
                        },
                      }));
                      const list = new Set(savedCopy?.list);

                      if (
                        hasTextChanged(
                          savedCopy["progress_note"]?.[key]?.text || "",
                          text
                        )
                      ) {
                        list.add(key);
                        setSavedCopy((prevState) => ({
                          ...prevState,
                          list,
                        }));
                        console.log(list);
                      } else {
                        list.delete(key);
                        setSavedCopy((prevState) => ({
                          ...prevState,
                          list,
                        }));
                      }
                    }}
                  />
                  {expertClinicalNotesData?.data?.progress_note?.[key]
                    ?.accepted !== "true" && (
                    <CNReviewBar section_={"progress_note"} key_={key} />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const handleRecommendedLabtestClick = (testName) => {
    setRecommendedTestModal({
      view: true,
      searchTerm: testName,
      label: "Recommended Lab Test",
    });
  };

  const handleRecommendLabtest = (data) => {
    if (data.length === 0) return;
    const newRow = {
      ...data,
      _id: generateRandomId(),
    };
    const existingRow =
      expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test || [];
    const updatedRow = [...existingRow, newRow];

    const list = new Set(savedCopy?.list);

    if (!isDeepEqual(updatedRow, savedCopy["lab_test"])) {
      list.add("lab_test");
      setSavedCopy((prevState) => ({
        ...prevState,
        list,
      }));
      console.log(list);
    } else {
      list.delete("lab_test");
      setSavedCopy((prevState) => ({
        ...prevState,
        list,
      }));
    }

    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        lab_test: {
          ...prevData.data.lab_test,
          prescribed_lab_test: updatedRow,
        },
      },
    }));
  };

  const handleBlur = async () => {
    const payload = {
      ...expertClinicalNotesData?.headerData,
      assistant: {},
      drugs:
        expertClinicalNotesData?.data?.progress_note?.medicines
          ?.prescribed_medicines,
      labtest: expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test,
      disease: "",
    };
    setSendData(true);
    HandleBlurForAssistant(payload)
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          return;
        }
      })
      .catch((err) => {
        console.error(err); // Optionally log the error internally here as well
      });
  };
  return (
    <div>
      <div>
        <div className="p-lg-3">
          <div className="w-100">
            {renderTextEditor(
              "chief_complaints",
              "Chief Complaint (CC)",
              "",
              "The patient's main reason for presenting."
            )}
          </div>
          <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
            {renderTextEditor(
              "history_of_present_illness_hpi",
              "History of Present Illness (HPI)",
              "",
              "More details elaborating on the Chief Complaint."
            )}
            {renderTextEditor(
              "session_summary",
              "Session Summary",
              "",
              "A rundown of everything that happened in the session."
            )}
          </div>
          <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
            {renderTextEditor(
              "homework_assigned",
              "Advice",
              "",
              "Your clinical recommendations for the patient, including any instructions they need to follow as part of their treatment. "
            )}
            {renderTextEditor(
              "future_treatment_plan",
              "Future Treatment Plan",
              "",
              "Your plans for upcoming sessions."
            )}
          </div>
          <div
            className={`w-100 SessionLabAdvice--Table ${
              !expertClinicalNotesData?.data?.template?.some(
                (item) => item.name === "medicines"
              ) &&
              !expertClinicalNotesData?.editTemplate &&
              "d-none"
            }`}
          >
            <h6>
              Medications to be Prescribed{" "}
              <HideUnhideHandler
                status={expertClinicalNotesData?.data?.template?.some(
                  (item) => item.name === "medicines"
                )}
                field={"medicines"}
              />
              <Tooltip
                arrow
                title={
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "white" }}
                  >
                    Medicines that you want to prescribe to the patient.
                  </p>
                }
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.black",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "common.white",
                      padding: "10px",
                    },
                  },
                }}
              >
                {" "}
                <InfoOutlined sx={{ fontSize: "16px", color: "black" }} />{" "}
              </Tooltip>
            </h6>

            {expertClinicalNotesData?.data?.template?.some(
              (item) => item.name === "medicines"
            ) && <PrescriptionMedicineUpdate />}
          </div>

          <RecommendedLabTestDialog
            openDialog={recommendedTestModal.view}
            closeDialog={() => {
              setRecommendedTestModal({
                view: false,
                searchTerm: "",
                label: "",
              });
            }}
            recommendation={recommendedTestModal.searchTerm}
            labtestData={(data) => handleRecommendLabtest(data)}
            label={recommendedTestModal.label}
          />

          <div
            style={{ overflowY: "auto" }}
            className={`w-100 SessionLabAdvice--Table ${
              !expertClinicalNotesData?.data?.template?.some(
                (item) => item.name === "lab_test"
              ) &&
              !expertClinicalNotesData?.editTemplate &&
              "d-none"
            }`}
          >
            <div
              style={{ gridTemplateColumns: "repeat(2, minmax(0, 1fr))" }}
              className="Table--Body gap-3 mt-2"
            >
              <div className="fw-semibold">
                Lab Test{" "}
                <Tooltip
                  arrow
                  title={
                    <p
                      className="m-0"
                      style={{ fontSize: "14px", color: "white" }}
                    >
                      Tests or diagnostics that you want the patient to take.
                    </p>
                  }
                  placement="top-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "common.black",
                        "& .MuiTooltip-arrow": {
                          color: "common.black",
                        },
                        color: "common.white",
                        padding: "10px",
                      },
                    },
                  }}
                >
                  <InfoOutlined sx={{ fontSize: "16px", color: "black" }} />
                </Tooltip>
                <HideUnhideHandler
                  status={expertClinicalNotesData?.data?.template?.some(
                    (item) => item.name === "lab_test"
                  )}
                  field={"lab_test"}
                />
              </div>
              {expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test
                ?.length > 0 && (
                <div className="fw-semibold">Time to result</div>
              )}
            </div>
            <div
              className={`${
                !expertClinicalNotesData?.data?.template?.some(
                  (item) => item.name === "lab_test"
                ) && "d-none"
              }`}
            >
              {isLoading ? (
                <LabtestsSkeleton />
              ) : (
                <>
                  {expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.map(
                    (row, index) => (
                      <div
                        style={{
                          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                          minWidth: "600px",
                        }}
                        className="Table--Body gap-2 mt-2 "
                        key={index}
                      >
                        <div className="Table-span-1">
                          <Autocomplete
                            fullWidth
                            disablePortal
                            freeSolo
                            id="combo-box-demo"
                            options={recommendedLabTest?.map(
                              (d, id) => d?.test_name
                            )}
                            onChange={(event, newValue) => {
                              handleInputChangeLab(
                                index,
                                "test_name",
                                newValue
                              );
                              setRecommendedLabTest([]);
                            }}
                            disabled={
                              expertClinicalNotesData?.data?.submitted ||
                              expertClinicalNotesData?.editTemplate
                            }
                            onBlur={handleBlur}
                            value={row?.test_name || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                label=""
                                value={row?.test_name || ""}
                                onChange={(e) =>
                                  handleInputChangeLab(
                                    index,
                                    "test_name",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  expertClinicalNotesData?.data?.submitted ||
                                  expertClinicalNotesData?.editTemplate
                                }
                              />
                            )}
                          />
                        </div>
                        <div className="Table-span-1">
                          <div className="d-flex align-items-center gap-2">
                            <TextField
                              fullWidth
                              size="small"
                              label=""
                              value={row?.time_to_result?.split(" ")?.[0] || ""}
                              onChange={(e) =>
                                handleInputChangeLab(
                                  index,
                                  "time_to_result",
                                  e.target.value
                                )
                              }
                              disabled={
                                expertClinicalNotesData?.data?.submitted ||
                                expertClinicalNotesData?.editTemplate
                              }
                            />
                            <FormControl size="small" fullWidth>
                              <Select
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                value={
                                  row?.time_to_result?.split(" ")?.[1] || "days"
                                }
                                onChange={(e) =>
                                  handleInputChangeLab(
                                    index,
                                    "time_period",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  expertClinicalNotesData?.data?.submitted ||
                                  expertClinicalNotesData?.editTemplate
                                }
                              >
                                <MenuItem value={""} disabled>
                                  Select an option
                                </MenuItem>
                                <MenuItem value={"hours"}>Hour(s)</MenuItem>
                                <MenuItem value={"days"}>Day(s)</MenuItem>
                                <MenuItem value={"weeks"}>Week(s)</MenuItem>
                                <MenuItem value={"months"}>Month(s)</MenuItem>
                                <MenuItem value={"years"}>Year(s)</MenuItem>
                              </Select>
                            </FormControl>

                            {!expertClinicalNotesData?.data?.submitted && (
                              <Button
                                sx={{ minWidth: "max-content" }}
                                onClick={() => handleDeleteLabTest(index)}
                                disabled={
                                  expertClinicalNotesData?.data?.submitted ||
                                  expertClinicalNotesData?.editTemplate
                                }
                              >
                                <Delete className="text-danger delete_labtest" />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  {/* Hidden for  recommended labtest but functional for labtest recommended Do not touch */}
                  <div className="d-none gap-2 p-2 align-items-center mt-2">
                    <div className="fw-semibold lh-base text-black">
                      Recommended Lab Test
                    </div>
                    {[{ name: "CBC" }]?.map((test, index) => (
                      <div
                        role="button"
                        onClick={() => handleRecommendedLabtestClick(test.name)}
                        style={{
                          borderRadius: "100px",
                          background: "var(--primary-accent-color)",
                          padding: "10px",
                        }}
                      >
                        <AddCircle sx={{ color: "var(--primary-color)" }} />{" "}
                        {test.name}
                      </div>
                    ))}
                  </div>

                  {!expertClinicalNotesData?.data?.submitted && (
                    <Button
                      onClick={() =>
                        setRecommendedTestModal({
                          view: true,
                          searchTerm: "",
                          label: "Add Lab Test",
                        })
                      }
                      disabled={
                        expertClinicalNotesData?.data?.submitted ||
                        expertClinicalNotesData?.editTemplate
                      }
                      sx={{ marginTop: "20px" }}
                      className="PrimaryCTA"
                      id="add_new_lab_test"
                    >
                      Add New Lab Test
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            style={{ overflowY: "auto" }}
            className={`w-100 mt-2 ${
              !expertClinicalNotesData?.data?.template?.some(
                (item) => item.name === "follow_up_date"
              ) &&
              !expertClinicalNotesData?.editTemplate &&
              "d-none"
            }`}
          >
            {" "}
            <h6 className="d-flex gap-2 align-items-center">
              Follow Up{" "}
              <HideUnhideHandler
                status={expertClinicalNotesData?.data?.template?.some(
                  (item) => item.name === "follow_up_date"
                )}
                field={"follow_up_date"}
              />{" "}
              <Tooltip
                arrow
                title={
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "white" }}
                  >
                    The date of the next session.
                  </p>
                }
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.black",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "common.white",
                      padding: "10px",
                    },
                  },
                }}
              >
                <InfoOutlined sx={{ fontSize: "16px", color: "black" }} />
              </Tooltip>
            </h6>
            <div
              className={`${
                !expertClinicalNotesData?.data?.template?.some(
                  (item) => item.name === "follow_up_date"
                ) && "d-none"
              } w-100 SessionPrescription--Table`}
              style={{ minWidth: "600px" }}
            >
              <div className="Table--Head  gap-3">
                <div className="Table-span-6">Follow Up In </div>
                <div className="Table-span-6">Follow Up Date </div>
              </div>
              {isLoading ? (
                <FollowUpdateSkeleton />
              ) : (
                <FollowUpDateCaclulator
                  date={(date) => {
                    const updatedData = {
                      ...expertClinicalNotesData.data.progress_note,
                      follow_up_date: date,
                    };
                    const list = new Set(savedCopy?.list);

                    if (
                      hasTextChanged(
                        typeof savedCopy["progress_note"]?.[
                          "follow_up_date"
                        ] === "object"
                          ? dayjs().format("YYYY-MM-DD")
                          : savedCopy["progress_note"]?.["follow_up_date"] ||
                              "",
                        date
                      )
                    ) {
                      list.add("follow_up_date");
                      setSavedCopy((prevState) => ({
                        ...prevState,
                        list,
                      }));
                      console.log(list);
                    } else {
                      list.delete("follow_up_date");
                      setSavedCopy((prevState) => ({
                        ...prevState,
                        list,
                      }));
                    }
                    setExpertClinicalNotesData((prevData) => ({
                      ...prevData,
                      data: {
                        ...prevData.data,
                        progress_note: {
                          ...prevData.data.progress_note,
                          follow_up_date: date,
                        },
                      },
                    }));
                  }}
                  setDate={
                    expertClinicalNotesData?.data?.progress_note
                      ?.follow_up_date || ""
                  }
                  disabled={
                    expertClinicalNotesData?.data?.submitted ||
                    expertClinicalNotesData?.editTemplate
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CNSessionNote;
