import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../context";
// Components
import NotesSkeltonLoader from "./Skeletons/NotesSkeltonLoader";
import HistoricalSessionNotesSkeleton from "./Skeletons/HistoricalSessionNotesSkeleton";
import CaseHistoryDropdownSkeleton from "./Skeletons/CaseHistoryDropdownSkeleton";
import TextEditor from "./Components/TextEditor";
import CNReviewBar from "./CNReviewBar";
import NoteFromClinician from "../tables/NoteFromClinician";
import TextEditorCommonSkeleton from "./Skeletons/TextEditorCommonSkeleton";

// Dropdown Data
import {
  relationshipStatuses,
  maritalFunctioningStatuses,
  employmentStatuses,
  socialFunctioningStatuses,
  academicFunctioning,
} from "./ClinicalNotesAPI/DropdownData";

// MUI
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import HideUnhideHandler from "./Components/HideUnhideHandler";
import { hasTextChanged } from "./lib/utils";

function CNCaseHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const {
    expertClinicalNotesData,
    setExpertClinicalNotesData,
    savedCopy,
    setSavedCopy,
  } = useContext(AppContext);

  useEffect(() => {
    if ((expertClinicalNotesData?.data?.case_history || {}).length !== 0) {
      setIsLoading(false);
    }
  }, [expertClinicalNotesData?.data?.case_history]);

  useEffect(() => {
    const updateOptionsIfNeeded = (key, optionsArray) => {
      if (
        !expertClinicalNotesData?.data?.case_history?.[key]?.options?.length > 0
      ) {
        setExpertClinicalNotesData((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            case_history: {
              ...prevState?.data?.case_history,
              [key]: {
                options: optionsArray.map((status) => ({
                  option_text: status,
                  selected: "false",
                })),
              },
            },
          },
        }));
      }
    };

    updateOptionsIfNeeded("marital_status", relationshipStatuses);
    updateOptionsIfNeeded("employment_status", employmentStatuses);
    updateOptionsIfNeeded("marital_functioning", maritalFunctioningStatuses);
    updateOptionsIfNeeded("social_functioning", socialFunctioningStatuses);
    updateOptionsIfNeeded("academic_functioning", academicFunctioning);
  }, [expertClinicalNotesData?.data?.case_history]);

  const renderTextEditor = (key, labelText) => {
    const initialText =
      expertClinicalNotesData?.data?.case_history?.[key]?.text || "";
    return (
      <div
        className={`w-100 mt-4 ${
          !expertClinicalNotesData?.data?.template?.some(
            (item) => item.name === key
          ) &&
          !expertClinicalNotesData?.editTemplate &&
          "d-none"
        }`}
      >
        <h6>
          {labelText}{" "}
          {/* {" Completed:" +
            expertClinicalNotesData?.data?.case_history?.[key]?.completed +
            " total:" +
            expertClinicalNotesData?.data?.case_history?.[key]?.total +
            " current:" +
            expertClinicalNotesData?.data?.case_history?.[key]?.current} */}
          <HideUnhideHandler
            status={expertClinicalNotesData?.data?.template?.some(
              (item) => item.name === key
            )}
            field={key}
          />
        </h6>
        <div
          className={`${
            !expertClinicalNotesData?.data?.template?.some(
              (item) => item.name === key
            ) && "d-none"
          }`}
        >
          <div className="position-relative">
            {" "}
            {expertClinicalNotesData?.data?.case_history?.[key]?.display ===
            "false" ? (
              <NotesSkeltonLoader />
            ) : (
              <>
                {!expertClinicalNotesData?.data?.case_history?.[key] ? (
                  <TextEditorCommonSkeleton />
                ) : (
                  <>
                    <TextEditor
                      initialText={initialText}
                      disabled={
                        expertClinicalNotesData?.data?.submitted ||
                        expertClinicalNotesData?.editTemplate
                      }
                      htmlText={(text) => {
                        setExpertClinicalNotesData((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState?.data,
                            case_history: {
                              ...prevState?.data?.case_history,
                              [key]: {
                                ...prevState?.data?.case_history[key],
                                text: text,
                                is_edited:
                                  text != null &&
                                  text?.trim() !== "" &&
                                  text?.replace(/&nbsp;/g, "") !== "<p></p>\n"
                                    ? "true"
                                    : null,
                              },
                            },
                          },
                        }));
                        const list = new Set(savedCopy?.list);
                        if (
                          hasTextChanged(
                            savedCopy["case_history"]?.[key]?.text || "",
                            text
                          )
                        ) {
                          list.add(key);
                          setSavedCopy((prevState) => ({
                            ...prevState,
                            list,
                          }));
                          console.log(list);
                        } else {
                          list.delete(key);
                          setSavedCopy((prevState) => ({
                            ...prevState,
                            list,
                          }));
                        }
                      }}
                    />
                    {expertClinicalNotesData?.data?.case_history?.[key]
                      ?.accepted !== "true" && (
                      <CNReviewBar section_={"case_history"} key_={key} />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleStatusChange = (event, key) => {
    const selectedIndex = event.target.value;
    const updatedData = {
      ...expertClinicalNotesData,
      data: {
        ...expertClinicalNotesData?.data,
        case_history: {
          ...expertClinicalNotesData?.data?.case_history,
          [key]: {
            ...expertClinicalNotesData?.data?.case_history[key],
            is_edited: "true",
            options: expertClinicalNotesData?.data?.case_history[
              key
            ]?.options?.map((option, index) => ({
              ...option,
              selected: index === selectedIndex ? "true" : "false",
            })),
          },
        },
      },
    };

    setExpertClinicalNotesData(updatedData);
    const list = new Set(savedCopy?.list);
    if (
      hasTextChanged(
        savedCopy["case_history"]?.[key]?.options[selectedIndex]?.selected ||
          "",
        updatedData?.data?.case_history?.[key]?.options[selectedIndex]?.selected
      )
    ) {
      list.add(key);
      setSavedCopy((prevState) => ({
        ...prevState,
        list,
      }));
    } else {
      list.delete(key);
      setSavedCopy((prevState) => ({
        ...prevState,
        list,
      }));
    }
  };

  const statusMappings = {
    marital_status: handleStatusChange,
    employment_status: handleStatusChange,
    marital_functioning: handleStatusChange,
    social_functioning: handleStatusChange,
    academic_functioning: handleStatusChange,
  };

  return (
    <div className="p-lg-4 pt-0">
      {isLoading ? (
        <HistoricalSessionNotesSkeleton />
      ) : (
        <NoteFromClinician title="Historical Session Notes" />
      )}

      {isLoading ? (
        <CaseHistoryDropdownSkeleton />
      ) : (
        <div className="mt-4 d-flex flex-column gap-4">
          {Object.keys(statusMappings)
            .reduce((rows, statusKey, idx) => {
              const lastRow = rows[rows.length - 1];
              if (!lastRow || lastRow.length === 2) {
                rows.push([statusKey]);
              } else {
                lastRow.push(statusKey);
              }
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <div
                key={rowIndex}
                className="d-flex flex-column flex-sm-row gap-4 "
              >
                {row.map((statusKey, idx) => (
                  <FormControl
                    size="small"
                    fullWidth
                    key={statusKey}
                    className={`${rowIndex % 2 === 0 ? "mt-2" : ""} ${
                      !expertClinicalNotesData?.data?.template?.some(
                        (item) => item.name === statusKey
                      ) &&
                      !expertClinicalNotesData?.editTemplate &&
                      "d-none"
                    }`}
                  >
                    <h6 className="mb-1 d-flex gap-2 align-items-center">
                      {" "}
                      {statusKey
                        .replace("_", " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                      <HideUnhideHandler
                        status={expertClinicalNotesData?.data?.template?.some(
                          (item) => item.name === statusKey
                        )}
                        field={statusKey}
                      />
                    </h6>
                    <div
                      className={`${
                        !expertClinicalNotesData?.data?.template?.some(
                          (item) => item.name === statusKey
                        ) && "d-none"
                      }`}
                    >
                      <Select
                        fullWidth
                        labelId={`select-label-${statusKey}`}
                        id={`select-${statusKey}`}
                        value={expertClinicalNotesData?.data?.case_history?.[
                          statusKey
                        ]?.options?.findIndex((op) => op.selected === "true")}
                        onChange={(event) =>
                          handleStatusChange(event, statusKey)
                        }
                        disabled={
                          expertClinicalNotesData?.data?.submitted ||
                          expertClinicalNotesData?.editTemplate
                        }
                      >
                        {expertClinicalNotesData?.data?.case_history?.[
                          statusKey
                        ]?.options?.map((status, index) => (
                          <MenuItem key={index} value={index}>
                            {status?.option_text}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </FormControl>
                ))}
              </div>
            ))}
        </div>
      )}

      <div className="mt-4">
        {/* <div className="d-flex gap-4 my-4">
          
          Date:30/03/2024 hidden by Sourav
          <div className="w-100">
            <h6>Current Medications</h6>
            <TextEditor
              initialText={
                expertClinicalNotesData?.data?.case_history?.current_medications
                  ?.text
              }
            />
          </div>
        </div> */}

        <div className="w-100 d-flex flex-column flex-lg-row gap-4">
          {renderTextEditor("medical_history", "Clinical History", "")}
          {renderTextEditor(
            "history_of_self_harm_aggression",
            "History of Self-Harm/Aggression",
            ""
          )}
        </div>

        <div className="w-100 d-flex flex-column flex-lg-row gap-4">
          {renderTextEditor("history_of_trauma", "History of Trauma", "")}
          {renderTextEditor(
            "history_of_contagious_medical_conditions",
            "History of Contagious Medical Conditions",
            ""
          )}
        </div>

        <div className="w-100 d-flex flex-column flex-lg-row gap-4">
          {renderTextEditor("medication_history", "Medication History", "")}
          {renderTextEditor(
            "hospitalization_history",
            "Hospitalization History",
            ""
          )}
        </div>

        <div className="w-100 d-flex flex-column flex-lg-row gap-4">
          {renderTextEditor(
            "surgical_history_shx",
            "Surgical History (SHx)",
            ""
          )}
          {renderTextEditor(
            "histopathological_pathological_history",
            "Histopathological/Pathological History",
            ""
          )}
        </div>

        <div className="w-100 d-flex flex-column flex-lg-row gap-4">
          {renderTextEditor("allergy_history", "Allergy History", "")}
          {renderTextEditor("nutritional_history", "Nutritional History", "")}
        </div>

        <div className="w-100 d-flex flex-column flex-lg-row gap-4">
          {renderTextEditor(
            "substance_use_issues",
            "Substance Dependence History",
            ""
          )}
          {renderTextEditor("genetics", "Genetics", "")}
        </div>

        <div className="w-100 d-flex flex-column flex-lg-row gap-4">
          {renderTextEditor(
            "family_history_of_mental_illness_or_substance_abuse_issues",
            "Family History (FHx)",
            ""
          )}
          {renderTextEditor(
            "psychosocial_history",
            "Social History (SocHx)",
            ""
          )}
        </div>

        <div className="w-100 d-flex flex-column flex-lg-row gap-4">
          {renderTextEditor("languages_spoken", "Languages Spoken", "")}
          {renderTextEditor("lifestyle_history", "Lifestyle History", "")}
        </div>

        <div className="w-100 d-flex flex-column flex-lg-row gap-4">
          {renderTextEditor(
            "developmental_history",
            "Developmental History",
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default CNCaseHistory;
