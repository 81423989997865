import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Button from "@mui/material/Button";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";


//JWT

import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";

//Firebase
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebaseConfig";
import { useAuth } from "../../../auth";
import { IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";



const RaiseTicketModalClinical = ({ isTickeSent }) => {
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ticketSubjectList, setTicketSubjectList] = useState([]);
  const [ticketSubject, setTicketSubject] = useState();
  const [ticketMessage, setTicketMessage] = useState("");
  const [isFileLoading, setIsFileLoading] = useState(null)
  const [isTicketSent, setIsTicketSent] = useState(null)

  const headers = {
    "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
    "Content-Type": "application/json",
  };

  const { currentUser, } = useAuth();

  useEffect(() => {
    if (!currentUser) return;


    const getSubjectsList = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/ticket-subjects?dashboard=copilot`,
          {
            method: "GET",
            headers,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postResponse = await response.json();
        const { subjects } = await postResponse.data;
        setTicketSubjectList(subjects);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getSubjectsList();
  }, []);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedFiles([]);
    setTicketSubject();
    setTicketMessage("");
    setOpen(false);
  };



  //Upload
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];

    // console.log("file", file);
    setIsFileLoading(true)
    if (
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg" ||
      file?.type === "application/pdf"
    ) {
      let dirName = moment().format("MMMM_YYYY");
      //console.log("dirName", dirName);
      const storageRef = ref(storage, dirName + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err) => enqueueSnackbar(err, { variant: "error" }),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setSelectedFiles(url);
            setIsFileLoading(false)
          });
        }
      );
    } else {
      console.error("Incorrect file format.");
      enqueueSnackbar("Only PDF, PNG, JPG and JPEG images are supported. Please try again.", { variant: "error" });
      setIsFileLoading(false)
    }
  };

  //Ticket



  const handleTicketSubject = (e) => {
    setTicketSubject(e.target.value);
  };

  const handleTicketMessage = (e) => {
    setTicketMessage(e.target.value);
  };

  const handleSendTicket = async () => {

    setIsTicketSent(false)
    const payload = {
      user_id: currentUser?.UserId,
      mail: currentUser?.Emailid,
      subject_id: ticketSubject,
      description: ticketMessage,
      file: selectedFiles,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/raise-ticket`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const postResponse = await response.json();

      const message = await postResponse.code;

      if (message === 200) {

        setTicketSubject();
        setTicketMessage("");
        setOpen(false);
        enqueueSnackbar("Ticket created Successfully", { variant: "success" });
        setSelectedFiles([]);
        setIsTicketSent(true)
        isTickeSent()
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        className="PrimaryCTA contact_support"
        id="contact_support"
        disableElevation
      >
        <AddCircleIcon />
        Contact Support
      </Button>

      <Dialog
        className="AddApointment--Dialog recommendation-assessment "
        open={open}
        onClose={handleClose}
        sx={{


        }}
      >
        {
          (isTicketSent !== null && !isTicketSent) && (
            <div style={{ width: '100%', height: '100%', background: 'rgb(183 216 237 / 58%)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )
        }

        <DialogTitle className="d-flex p-2 p-md-4  justify-content-between">Contact Support <IconButton
          onClick={handleClose}><Clear /></IconButton></DialogTitle>

        <DialogContent className="Dialog--Content p-2 p-md-4 ">
          <p
            style={{

              color: "#656565",
              fontSize: "16px",
              lineHeight: "130%",
            }}
          >
            Please raise your ticket here. Our support team will reach back to you
            soon.
          </p>
          <FormControl
            sx={{
              background: "#FFF",
              marginTop: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <InputLabel id="demo-simple-select-label" sx={{ background: "#FFF", padding: "0 5px" }}>Subject*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ticketSubject}
              onChange={handleTicketSubject}
              fullWidth
            >
              {ticketSubjectList?.map((item, i) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="outlined-multiline-static"
            label="Explain in few words*"
            multiline
            fullWidth
            rows={7}
            value={ticketMessage}
            onChange={handleTicketMessage}
            sx={{ marginTop: "15px" }}
          />

          <div className="upload-button">
            <label htmlFor="file-upload" className="upload-button-label">
              <FileUploadOutlinedIcon className="upload-button-icon" />
              <p className="m-0 d-none d-sm-block fs-6">Upload Files</p>
            </label>
            {
              isFileLoading !== null && isFileLoading ? (
                <div className="d-flex align-items-center mx-auto">
                  <CircularProgress sx={{ width: '20px !important', height: '20px !important' }} />
                </div>

              ) : (
                <>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*,.pdf"
                    multiple
                    onChange={handleFileSelect}
                    className="upload-button-input"
                  />
                  {selectedFiles && (
                    <div className="selected-files">
                      <div className="file-name">{selectedFiles}</div>
                    </div>
                  )}
                </>
              )
            }

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="PrimaryCTA raise_ticket"
            sx={{ marginLeft: "auto", marginRight: "auto" }}
            onClick={handleSendTicket}
            variant="contained"
            disableElevation
            disabled={ticketMessage.trim() === ""}
            id="raise_ticket"
            
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default RaiseTicketModalClinical;
