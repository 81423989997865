import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const ClientGeneralQuestionsTable = ({ questions, name }) => {
  return (
    <Box>
      {/* Table Header */}
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center", marginBottom: 2 }}
      >
        {name.charAt(0).toUpperCase() +
          name.slice(1).replace(/_/g, " ")}
      </Typography>

      {/* Table Container */}
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "90%",
          margin: "0 auto",
          borderRadius: 2,
          boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="Client General Questions">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>#</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Question</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions?.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                  "&:hover": { backgroundColor: "#f1f1f1" },
                }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.question}</TableCell>
                <TableCell>{item.response || "No response"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ClientGeneralQuestionsTable;
